import React, { Component } from 'react';
import {Button, Input, message, Modal} from "antd";
import './index.scss';
import initEditor from "@/component/Admin/Store/AboutUs/initEditor";
import Req from "@util/fetch";
import Tr from "@util/translate";

export default class HelpCenter extends Component{
    constructor(props){
        super(props)
    }
    state = {}

    componentDidMount() {
        this.getList();
    }

    openEditModdal = (data)=>{
        let editData = {
            content: ""
        }
        if(data) editData = { ...data };
        this.setState({
            editData: editData,
            visible: true
        }, ()=>{
            if(this.container){
                initEditor({
                    container: this.container,
                    setEditor: (editor)=>{ this.editor = editor },
                    html: editData.content
                });
            }else{
                setTimeout(()=>{
                    initEditor({
                        container: this.container,
                        setEditor: (editor)=>{ this.editor = editor },
                        html: editData.content
                    });
                }, 500)
            }
        })
    }

    getList = ()=>{
        Req({
            url: "/v1/copywritingSettings?type=1",
            type: "GET"
        }, res=>{
            if(res){
                this.setState({
                    list: res.data || []
                })
            }

        })
    }

    save = ()=>{
        const { editData } = this.state;
        let data = {
            ...editData,
            content: this.editor.txt.html(),
            type: 1
        };
        Req({
            url: "/v1/copywritingSettings",
            type: "POST",
            data
        }, res=> {
            if (res) {
                message.success(Tr("saveSuccess"));
                this.setState({
                    visible: false
                }, this.getList)
            }
        })
    }

    deleteAction = (item) => {
        Modal.confirm({
            title: Tr("tips"),
            content: Tr("delConfirm"),
            okText: Tr("sure"),
            cancelText: Tr("cancel"),
            onOk: ()=>{
                Req({
                        url: `/v1/copywritingSettings/${item.id}`,
                        type: 'DELETE'
                    },
                    res => {
                        if (res) {
                            message.success('删除成功！');
                            this.getList();
                        }
                    }
                );
            },
        });
    }

    render(){
        const { editData={}, visible, list=[] } = this.state;

        return (
            <div>
                <Button type="primary" icon="plus" style={{marginTop: 30}} onClick={ ()=>this.openEditModdal() }>{ Tr("discountList36") }</Button>
                <ul className="help-list">
                    {
                        (list || []).map((item, index) => {
                            return <li key={item.id}>
                                <div>{item.title || `${Tr('add166')}${index + 1}`}</div>
                                <div>
                                    <Button size="small" onClick={() =>this.openEditModdal(item)}>{ Tr("edit") }</Button>
                                    <Button size="small" type="danger"
                                            onClick={() => this.deleteAction(item)}>{ Tr("delete") }</Button>
                                </div>
                            </li>
                        })
                    }
                </ul>

                <Modal
                    destroyOnClose
                    className="help-modal"
                    title={ Tr("discountList36") }
                    visible={visible}
                    width={800}
                    footer={null}
                    onOk={() => {
                        this.setState({visible: false});
                    }}
                    onCancel={() => {
                        this.setState({visible: false});
                    }}
                >
                    <Input placeholder={ Tr("add89") } style={{width: 300}}
                           onChange={e => this.setState({editData: {...editData, title: e.target.value}})}
                           value={editData.title}/>

                    <div style={{ margin: '35px auto' }} ref={ container=>{ this.container = container } }></div>

                    <div className="btns">
                        <Button type="primary" onClick={() => this.save()}>
                            {Tr("save")}
                        </Button>
                    </div>
                </Modal>
            </div>
        )
    }
}
