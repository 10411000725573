export const actions = {
  userInfo(data) {
    return {
      type: "USERINFO",
      data
    };
  },
  //左侧菜单点餐点击次数记录 用于 刷新 点餐页面
  orderIndex(n) {
    return {
      type: "ORDERINDEX",
      data: n
    };
  },
  goodsList(data) {
    return {
      type: "GOODSLIST",
      data
    };
  },
  goodsKindList(data) {
    return {
      type: "GOODSKINDLIST",
      data
    };
  },
  staffList(data) {
    return {
      type: "STAFFLIST",
      data
    };
  },
  postList(data) {
    return {
      type: "POSTLIST",
      data
    };
  },
  propList(data) {
    return {
      type: "PROPLIST",
      data
    };
  },
  storeList(data) {
    return {
      type: "STORELIST",
      data
    };
  },
  couponsList(data) {
    return {
      type: "COUPONSLIST",
      data
    };
  },
  bannerList(data) {
    return {
      type: "BANNERLIST",
      data
    };
  },
  lang(data) {
    return {
      type: "LANG",
      data
    };
  },
};
