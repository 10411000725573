import React, { Component } from 'react';
import './index.scss';
import Tr from "@util/translate";
import {Button, DatePicker, Icon, Input, Pagination, Select} from "antd";
import Req from "@util/fetch";
import moment from 'moment';

const Option = Select.Option;

export default class DishRank extends Component{
    constructor(props){
        super(props)
    }
    state={
        queryParams: {
            page: 1,
            pageSize: 100,
            type: 1,
            timeType: 1
        }
    }

    componentDidMount(){
        this.getData();
    }

    queryParamsChange = (value, key)=>{
        const { queryParams } = this.state;
        this.setState({
            queryParams: {
                ...queryParams,
                beginTime: key === 'timeType' ? '' : queryParams.beginTime,
                endTime: key === 'timeType' ? '' : queryParams.endTime,
                [key]: value.target ? value.target.value : value
            }
        }, ()=>{
            this.getData();
        })
    }

    dateChange = (value, key)=>{
        const { queryParams } = this.state;
        this.setState({
            queryParams: {
                ...queryParams,
                timeType: 6,
                [key]: value.target ? value.target.value : value
            }
        })
    }

    getData = ()=>{
        const { queryParams } = this.state;
        let params = `?`;
        this.setState({ loading: true })
        for (let key in queryParams) {
            if(queryParams[key])params += `&${key}=${queryParams[key]}`;
        }
        Req({
            url: "/v1/customer/dishRank" + params,
            type: "GET"
        }, res=>{
            this.setState({ loading: false })
            if(res){
                this.setState({
                    list: res.data && res.data.collection,
                    total: res.data && res.data.totalCount
                })
            }
        })
    }

    render(){
        const { queryParams, list=[], loading, total } = this.state;
        return (
            <div className="page-container admin-statistics-list-page">
                <h3>
                    <Icon type="bar-chart" />
                    {Tr("add30")}
                </h3>

                <div className="query">
                    <div className="items">
                        {
                            [Tr('add212'),Tr('add213'),Tr('add214'),Tr('add215'), Tr('add216')].map((item, index)=>(
                                <div className={`item ${ queryParams.timeType === index + 1 ? 'active' : '' }`} onClick={ ()=>this.queryParamsChange(index + 1, 'timeType') }>{ item }</div>
                            ))
                        }
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <DatePicker
                        value={ queryParams.beginTime ? moment(queryParams.beginTime) : null }
                        onChange={(date, string) => {
                            this.dateChange(string, "beginTime");
                        }}
                        placeholder=" "
                        showToday={false}
                        locale="zh-cn"
                    />
                    <span>&nbsp;&nbsp;{Tr("orderList3")}</span>
                    <DatePicker
                        value={ queryParams.endTime ? moment(queryParams.endTime) : null }
                        onChange={(date, string) => {
                            this.dateChange(string, "endTime");
                        }}
                        placeholder=" "
                        showToday={false}
                        locale="zh-cn"
                    />

                    <Button type="primary" onClick={ ()=>this.queryParamsChange(1, 'page') }>
                        {Tr("user6")}
                    </Button>

                    <br/>
                    <br/>

                    <div className="inline-block items">
                        {
                            [1,2,3].map(item=>(
                                <div className={`item ${ queryParams.type === item ? 'active' : '' }`} onClick={ ()=>this.queryParamsChange(item, 'type') }>{ Tr(`add3${item}`) }</div>
                            ))
                        }
                    </div>
                </div>

                <div className="table-list">
                    <table>
                        <thead>
                        <tr>
                            <th>{Tr("no")}</th>
                            <th>{Tr("add34")}</th>
                            <th>{Tr("add35")}</th>
                            <th>{Tr("add32")}</th>
                            <th>{Tr("add36")}</th>
                            <th>{Tr("add33")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            list.map((item, index)=>{
                                return (
                                    <tr>
                                        <td>{ index + 1 }</td>
                                        <td><img src={item.path} alt=""/></td>
                                        <td>{item.name}</td>
                                        <td>{item.Quantity}</td>
                                        <td>{item.day_avg}</td>
                                        <td>{item.total_price}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>

                    {
                        (!list || !list.length ) && !loading ? (
                            <div className="noData">{Tr("bill15")}</div>
                        ) : null
                    }

                    <Pagination
                        onChange={ value=>{ this.queryParamsChange(value, 'page') }  }
                        pageSize={ queryParams.pageSize }
                        current={ queryParams.page }
                        total={ total || 0 }
                    />
                </div>
            </div>
        )
    }
}
