import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {message, Modal} from "antd";
import classnames from "classnames";
import Req from "@/util/fetch";
import Tr from "@util/translate";
import {actions} from "@/redux/action/common";
import SelectAccount from './../SlectAccount';
import "./index.scss";
import OpenCash from "./component/OpenCash";
import PrinterOrder from "./component/PrinterOrder";

const { confirm } = Modal;

@connect(
    state => {
        return {
            userInfo: state.global.userInfo,
            orderIndex: state.global.orderIndex
        };
    },
    dispatch => {
        return {
            orderIndexAction: n => dispatch(actions.orderIndex(n))
        };
    }
)
class LeftMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderData: {}
        };
    }

    componentDidMount() {
        window.setNowOrderData = data => {
            this.setState({
                orderData: data
            });
        };
        this.getStoreData();

        document.addEventListener('keydown', this.keydownAction);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownAction)
    }

    getStoreData = () => {
        Req(
            {
                url: `/v1/account`,
                type: "GET",
                hideLoading: true
            },
            res => {
                if (res) {
                    this.setState({
                        storeData: (res.data && res.data.currentStore) || {}
                    });
                    localStorage.setItem("storeData", JSON.stringify(res.data));
                }
            }
        );
    };

    keydownAction = (e)=>{
        let { orderIndexAction, orderIndex=0, history } = this.props;
        if(new Date()) return ;
        if(e.key === 'F1'){
            if(!location.hash.includes('/content/choice')){
                history.push('/content/choice');
            }else{
                orderIndexAction(++orderIndex);
            }
        }else if(e.key === 'F2'){
            if(location.pathname !== '/content/choice') history.push('/content/choice');
            openTakewayusers();
        }else if(e.key === 'F3'){
            this.gotoPage(null, "/content/index", 'VIEW_REPORT')
        }else if(e.key === 'F4'){
            window.globalOpenCash && window.globalOpenCash();
        }
        if(['F1', 'F2', 'F3', 'F4'].includes(e.key)){
            e.stopPropagation();
            e.preventDefault();
        }
    }

    loginOut = () => {
        confirm({
            title: Tr("leftMenu1"),
            content: Tr("leftMenu2"),
            okText: Tr("sure"),
            cancelText: Tr("cancel"),
            onOk: () => {
                localStorage.removeItem("storeData");
                localStorage.removeItem("userInfo");
                localStorage.removeItem("lang");
                window.clearGoodsCache && window.clearGoodsCache();
                location.hash = "/";
            },
            onCancel() {
            }
        });
    };

    getAccountList= ()=>{
        if(!localStorage.getItem("storeSerial2")){
            location.hash = "/";
            return ;
        }
        const { userList } = this.state;
        if(userList && userList.length>1){
            this.setState({
                showChangeAccount: true
            });
            return ;
        }else if(userList && userList.length === 1){
            this.loginOut();
        }
        let storeSerial = 'E' + localStorage.getItem("storeSerial2");
        Req({
            url: `/v1/employees/filter?storeSerial=${storeSerial}`,
            type: "GET",
            loadingTips: Tr("serachuser")
        }, res=>{
            if(res && res.data && res.data.length && res.data.length>1){
                this.setState({
                    userList: res.data,
                    showChangeAccount: true
                })
            }else{
                this.loginOut();
            }
        })
    }

    gotoPage = (e, url, code)=>{
        if(window.hasPermisionCode(code)){
            window.confrimPasswordAction(code, ()=>{
                location.hash = url;
            });
        }else{
            alert(Tr("noOpenChashPer"));
            e.stopPropagation();
        }
    }

    render() {
        const { showChangeAccount, userList, orderData } = this.state;
        let {location, userInfo = {}, orderIndexAction, orderIndex = 0, history} = this.props || {};
        return (
            <aside>
                <div className="head-img">
                    <img onClick={this.getAccountList} src={userInfo && userInfo.headImage}/>
                    <br/>
                    <span>{userInfo.name}</span>
                </div>

                <div className="btn-container">
                    <Link to="/content/choice">
                        <div
                            className={classnames({
                                menu: true,
                                active: location.pathname === "/content/choice"
                            })}
                            title={'F1'}
                        >
                            <i className="icon iconfont icon-ERP_xiadan"></i>
                            <span>{Tr("recircle1")}</span>
                        </div>
                    </Link>

                    <Link to="/content/today">
                        <div
                            className={classnames({
                                menu: true,
                                active: location.pathname === "/content/today"
                            })}
                            title={'F2'}
                        >
                            <i className="icon iconfont icon-qitadingdan"></i>
                            <span>{Tr("add233")}</span>
                        </div>
                    </Link>

                    <Link to="/content/history">
                        <div
                            className={classnames({
                                menu: true,
                                active: location.pathname === "/content/history"
                            })}
                            title={'F2'}
                        >
                            <i className="icon iconfont icon-lishijilu_o"></i>
                            <span>{Tr("recircle12")}</span>
                        </div>
                    </Link>

                    <PrinterOrder orderData={ orderData } />
                </div>

                <Modal
                    title={Tr("add160")}
                    width={ 800 }
                    className="select-account-modal"
                    visible={ showChangeAccount }
                    onCancel={ ()=>this.setState({ showChangeAccount: false }) }
                    footer={ null }
                >
                    <SelectAccount close={ ()=>this.setState({ showChangeAccount: false }) }
                                   userList={ userList || [] } />
                </Modal>
            </aside>
        );
    }
}

export default withRouter(LeftMenu);

function openTakewayusers(){
    setTimeout(()=>{
        if(!window.addTakeawayUsers){
            openTakewayusers();
            return ;
        }
        window.addTakeawayUsers && window.addTakeawayUsers();
    }, 200)
}
