import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import {
    AboutUs,
    BusinessHours,
    DiscountEdit,
    DiscountList,
    BannerEdit,
    GoodsEdit,
    GoodsKindsEdit,
    GoodsKindsList,
    GoodsList,
    SaleData,
    OrderDetail,
    OrderList,
    PaySetting,
    PostEdit,
    PostList,
    Printer,
    PropEdit,
    PropList,
    RoleEdit,
    RoleList,
    StaffEdit,
    StaffList,
    StoreEdit,
    TableManage,
    TurnOver,
    TurnOverOrder,
    UpdatePwd,
    UserList,
    UserDetail,
    UserOrder,
    Statistics,
    CustomerRank,
    DishRank,
    PublishDetail,
    StoreSetting,
    StoreAddBank,
    InvoiceList,
    MailTemplate,
    MailTemplateEdit,
    MailPush,
    MailPushDetail,
    TodayOrder
} from "./../component";
import LeftMenu from "./LeftMenu";
import {actions} from "@/redux/action/common";
import "./index.scss";
import StoreList from "@/component/Admin/Store/StoreList";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

export default @connect(
    state => {
        return {
            userInfo: state.global.userInfo || null,
        };
    },
    dispatch => {
        return {
            storeListAction: data => {
                dispatch(actions.storeList(data));
            }
        };
    }
)
class Admin extends Component {
    constructor() {
        super();
        this.socket = null;
    }

    componentDidMount() {
        //保存部分页面的查询参数以保证返回时可以还原前面的状态
        window.pageQueryParams = {
            userListQuery: null
        }
    }
    componentWillUnmount(){
        window.pageQueryParams = null;
    }

    render() {
        return (
            <div className="admin-page">
                <div className="content-container">
                    <LeftMenu/>
                    {
                        <DndProvider backend={ HTML5Backend }>
                            <div className="content">
                                <Switch>
                                    <Route path="/admin" exact component={Statistics}/>
                                    <Route path="/admin/today" exact component={TodayOrder}/>
                                    <Route path="/admin/rank" exact component={Statistics}/>
                                    <Route path="/admin/rank/customer" exact component={CustomerRank}/>
                                    <Route path="/admin/rank/dish" exact component={DishRank}/>
                                    <Route path="/admin/publish/:id" exact component={PublishDetail}/>
                                    <Route path="/admin/goods" exact component={GoodsList}/>
                                    <Route path="/admin/goods/list" component={GoodsList}/>
                                    <Route path="/admin/goods/edit/:id" component={GoodsEdit}/>
                                    <Route path="/admin/goodsprop/list" component={PropList}/>
                                    <Route path="/admin/goodsprop/edit/:id" component={PropEdit}/>
                                    <Route path="/admin/gkinds/list" component={GoodsKindsList}/>
                                    <Route path="/admin/gkinds/edit/:id" component={GoodsKindsEdit}/>
                                    <Route path="/admin/staff/list" component={StaffList}/>
                                    <Route path="/admin/staff/pwd/:id" component={UpdatePwd}/>
                                    <Route path="/admin/user/pwd/:id" component={UpdatePwd}/>
                                    <Route path="/admin/staff/edit/:id" component={StaffEdit}/>
                                    <Route path="/admin/post/list" component={PostList}/>
                                    <Route path="/admin/post/edit/:id" component={PostEdit}/>
                                    <Route path="/admin/role/list" component={RoleList}/>
                                    <Route path="/admin/role/edit/:id" component={RoleEdit}/>
                                    <Route path="/admin/order/list" component={OrderList}/>
                                    <Route path="/admin/invoice/list" component={InvoiceList}/>
                                    <Route path="/admin/userlist" exact component={UserList}/>
                                    <Route path="/admin/buy/order" component={OrderList}/>
                                    <Route path="/admin/buy/sale" component={SaleData}/>
                                    <Route path="/admin/buy/invoice" component={InvoiceList}/>
                                    <Route path="/admin/buy/userlist" exact component={UserList}/>
                                    <Route path="/admin/buy/userlist2" exact component={UserList}/>
                                    <Route path="/admin/sell/order" component={OrderList}/>
                                    <Route path="/admin/sell/sale" component={SaleData}/>
                                    <Route path="/admin/sell/invoice" component={InvoiceList}/>
                                    <Route path="/admin/sell/userlist" exact component={UserList}/>
                                    <Route path="/admin/sell/userlist2" exact component={UserList}/>
                                    <Route path="/admin/order/detail/:id" component={OrderDetail}/>
                                    <Route path="/admin/store/list" component={StoreList}/>
                                    <Route path="/admin/store1/setting" component={StoreSetting}/>
                                    <Route path="/admin/store/edit/:id" component={StoreEdit}/>
                                    <Route path="/admin/store/bank/:id" component={StoreAddBank}/>
                                    <Route path="/admin/printer" component={Printer}/>
                                    <Route path="/admin/paysetting" component={PaySetting}/>
                                    <Route path="/admin/aboutus" component={AboutUs}/>
                                    <Route path="/admin/table" component={TableManage}/>
                                    <Route path="/admin/discount/list" component={DiscountList}/>
                                    <Route path="/admin/discount/edit/:id" component={DiscountEdit}/>
                                    <Route path="/admin/banner/edit/:id" component={BannerEdit}/>
                                    <Route path="/admin/businesshours" component={BusinessHours}/>
                                    <Route path="/admin/turnover/order" component={TurnOverOrder}/>
                                    <Route path="/admin/turnover/:type" component={TurnOver}/>
                                    <Route path="/admin/template" exact component={MailTemplate}/>
                                    <Route path="/admin/template/:id" component={MailTemplateEdit}/>
                                    <Route path="/admin/push" exact component={MailPush}/>
                                    <Route path="/admin/push/:id" component={MailPushDetail}/>
                                    <Route path="/admin/userorder/:id" component={UserOrder}/>
                                    <Route path="/admin/userdetail/:id" component={UserDetail}/>
                                </Switch>
                            </div>
                        </DndProvider>
                    }
                </div>
            </div>
        );
    }
}