import React, { Fragment } from 'react';
import Tr from "@util/translate";
import {Input, Modal, Select, Button} from "antd";
import './index.scss';

const {TextArea} = Input;

export default class ComSetting extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            list: [],
            comValue: null,
            jsCode: 'let result = window.jsObj.GetComs();\r\nwindow.currentComponent.setState({ result });'
        }
    }
    componentDidMount() {
        this.getList();
        this.getComSetting();
    }
    getList = ()=>{
        if(!window.jsObj) return ;
        let comStr = window.jsObj.GetComs && window.jsObj.GetComs() || '';
        console.log(comStr);
        this.setState({
            list: comStr.split(',')
        })
    }
    getComSetting = ()=>{
        if(!window.jsObj) return ;
        const { initShow } = this.props;
        let comValue = window.jsObj.GetComSetting && window.jsObj.GetComSetting() || '';
        if(initShow && !comValue){
            this.setState({
                visible: true
            })
        }
        this.setState({
            comValue
        })
    }
    setCom = ()=>{
        if(!window.jsObj) return ;
        const { comValue } = this.state;
        if(!comValue) return ;
        window.jsObj.SetCommniCom && window.jsObj.SetCommniCom(comValue, ()=>{
            this.setState({
                visible: false
            })
        });
    }
    excuteJscode = ()=>{
        const { jsCode } = this.state;
        window.currentComponent = this;
        try{
            eval(jsCode);
        }catch (e){
            console.log(e);
            this.setState({
                result: `错误：${JSON.stringify(e.message)}`
            })
        }
    }
    render() {
        const { visible, comValue, list, result, jsCode } = this.state;
        const { children } = this.props;
        return (
            <Fragment>
                <div onClick={ (e)=>{
                    window.jsObj && window.jsObj.SetEleCom && window.jsObj.SetEleCom();
                    e.stopPropagation();
                    e.preventDefault();
                } }>
                    { children }
                </div>
                <Modal
                    className="com-setting-modal"
                    visible={ visible }
                    title={Tr("recircle10")}
                    onOk={ this.setCom }
                    okText={ Tr('save') }
                    cancelText={ Tr('cancel') }
                    onCancel={() => {
                        this.setState({visible: false});
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="key" style={{ whiteSpace: 'nowrap' }}>{ Tr('recircle11') }:</div>
                        <Select value={ comValue }
                                style={{ width: '100%', marginLeft: 20 }}
                                onChange={comValue => {
                                    this.setState({ comValue })
                                }}>
                            {
                                list.map((com, index)=>{
                                    return <Option value={com} key={`com_${index}`}>{ com }</Option>
                                })
                            }
                        </Select>
                    </div>
                    {
                        location.href.includes('localhost') ?
                            <Fragment>
                                <div style={{ display: 'flex', alignItems: 'center', margin: "20px 0" }}>
                                    <div className="key" style={{ whiteSpace: 'nowrap' }}>运行代码:</div>
                                    <TextArea rows={6} style={{ width: '100%', marginLeft: 20 }} value={ jsCode } onChange={ e=>this.setState({ jsCode: e.target.value }) } />
                                </div>
                                <div style={{ textAlign: 'right', marginBottom: 20 }} >
                                    <Button type="primary" onClick={ this.excuteJscode }>执行</Button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="key" style={{ whiteSpace: 'nowrap' }}>执行结果:</div>
                                    <TextArea rows={6} style={{ width: '100%', marginLeft: 20 }} value={ result } />
                                </div>
                            </Fragment>
                            : null
                    }
                </Modal>
            </Fragment>
        )
    }
}