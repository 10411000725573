import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Menu, Icon } from "antd";
import Tr from "@util/translate";
import ComSetting from "@/component/Admin/ComSetting";
import "./index.scss";

const {Item} = Menu;
const SubMenu = Menu.SubMenu

export default
@withRouter
@connect(
    state => {
        return {
            userInfo: state.global.userInfo || null,
        };
    },
    dispatch => { return {}; }
)
class LeftMenu extends Component {
    constructor() {
        super();
    }
    state = {
        openKeys: []
    };
    gotoPage = (e, url, code)=>{
        if(!code){
            location.hash = url;
            return ;
        }
        if(window.hasPermisionCode(code)){
            window.confrimPasswordAction(code, ()=>{
                location.hash = url;
            });
        }else{
            alert(Tr("noOpenChashPer"));
            e.stopPropagation();
        }
    }
    getLogoUrl = ()=>{
        const storeData = JSON.parse(localStorage.getItem("storeData")) || {};
        return storeData.currentStore && storeData.currentStore.logo && storeData.currentStore.logo.url || './public/img/logo.png';
    }

    render() {
        const {location} = this.props || {};
        const { openKeys } = this.state;
        let selected =
            location.pathname
                .split("/")
                .splice(0, location.pathname.includes('/buy') || location.pathname.includes('/sell') ? 4 : 3)
                .join("/");
        selected = selected.slice(0);

        let guid ;
        if(localStorage.getItem("userInfo")){
            guid = JSON.parse(localStorage.getItem("userInfo")).currentStoreGuid
        }else{
            location.hash="/"
        }

        return (
            <div className="admin-left-menu">
                <div className="logo-container">
                    <Link to="/content/choice" onClick={ ()=>{
                        window.getAllGoodsApiRoot && window.getAllGoodsApiRoot();
                    } }>
                        <img src={ this.getLogoUrl() } alt="" onError={ e=>{
                            e.target.src = './public/img/logo.png';
                        } }/>
                    </Link>
                </div>
                <Menu
                    style={{width: "100%"}}
                    mode="inline"
                    theme="dark"
                    onClick={this.menuClick}
                    openKeys={ openKeys }
                    onOpenChange={ openKeys => {
                        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
                        this.setState({
                            openKeys: [latestOpenKey]
                        });
                    }}
                    defaultSelectedKeys={[selected]}
                >
                    <Item key="/admin/today">
                        <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/today", '') }>
                            <Icon style={{ marginRight: 14 }} type="bar-chart" />
                            { Tr("recircle7") }
                        </a>
                    </Item>
                    {/*<Item key="/admin/rank" onClick={ ()=>this.setState({ openKeys: [] }) }>*/}
                    {/*    <Link to="/admin/rank">*/}
                    {/*        <Icon style={{ marginRight: 14 }} type="bar-chart" />*/}
                    {/*        <span>{ Tr("add43") }</span>*/}
                    {/*    </Link>*/}
                    {/*</Item>*/}
                    <SubMenu key="goods" title={<span><i
                        className="icon iconfont icon-shangpinliebiao"> </i><span>{ Tr("adminLeftMenu1") }</span></span>}>
                        <Item key="/admin/goods">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/goods/list", 'COMMODITY_LIST') }>
                                { Tr("adminLeftMenu2") }
                            </a>
                        </Item>
                        {/*<Item key="/admin/gkinds">*/}
                        {/*    <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/gkinds/list", 'COMMODITY_ CATEGORY') }>*/}
                        {/*        { Tr("adminLeftMenu3") }*/}
                        {/*    </a>*/}
                        {/*</Item>*/}
                        <Item key="/admin/goodsprop">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/goodsprop/list", 'COMMODITY_ATTRIBUTES') }>
                                { Tr("adminLeftMenu4") }
                            </a>
                        </Item>
                    </SubMenu>
                    <SubMenu key="role" title={<span><i
                        className="icon iconfont icon-yuangongliebiao"> </i><span>{ Tr("adminLeftMenu5") }</span></span>}>
                        <Item key="/admin/staff">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/staff/list", 'EMPLOYEE_LIST') }>
                                { Tr("adminLeftMenu6") }
                            </a>
                        </Item>
                        <Item key="/admin/role">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/role/list", 'EMPLOYEE_STATUS') }>
                                { Tr("adminLeftMenu7") }
                            </a>
                        </Item>
                    </SubMenu>
                    <SubMenu key="store"
                             title={<span><i className="icon iconfont icon-mendian"></i> <span>{ Tr("adminLeftMenu9") }</span></span>}>
                        {/*<Item key="/admin/discount">*/}
                        {/*    <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/discount/list", 'TAKEAWAY') }>*/}
                        {/*        { Tr("adminLeftMenu10") }*/}
                        {/*    </a>*/}
                        {/*</Item>*/}
                        {/*<Item key="/admin/store1">*/}
                        {/*    <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/store1/setting", 'RESTAURANT') }>*/}
                        {/*        { Tr("add57") }*/}
                        {/*    </a>*/}
                        {/*</Item>*/}
                        <Item key="/admin/aboutus">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/aboutus", 'ABOUT_US') }>
                                { Tr("add170") }
                            </a>
                        </Item>
                        <Item key="/admin/store">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/store/edit/${guid}`, 'STORE_INFO') }>
                                { Tr("adminLeftMenu12") }
                            </a>2
                        </Item>
                        {/*<Item key="/admin/businesshours">*/}
                        {/*    <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/businesshours`, 'BUSINESS_HOURS') }>*/}
                        {/*        { Tr("adminLeftMenu13") }*/}
                        {/*    </a>*/}
                        {/*</Item>*/}
                        {/*<Item key="/admin/table">*/}
                        {/*    <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/table`, 'TABLE_SETTING') }>*/}
                        {/*        { Tr("adminLeftMenu14") }*/}
                        {/*    </a>*/}
                        {/*</Item>*/}
                        <Item key="/admin/printer">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/printer`, 'BASIC_SETTING') }>
                                { Tr("adminLeftMenu15") }
                            </a>
                        </Item>
                        {/*<Item key="/admin/paysetting">*/}
                        {/*    <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/paysetting`, 'PAY_SETTING') }>*/}
                        {/*        { Tr("payCardSetting1") }*/}
                        {/*    </a>*/}
                        {/*</Item>*/}
                    </SubMenu>
                    <SubMenu key="/admin/buy"
                             title={<span><i className="icon iconfont icon-dingdan"></i> <span>{ Tr("recircle13") }</span></span>}>
                        <Item key="/admin/buy/order">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/buy/order?type=0") }>
                                { Tr("adminLeftMenu17") }
                            </a>
                        </Item>
                        <Item key="/admin/buy/sale">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/buy/sale?type=0") }>
                                { Tr("recircle32") }
                            </a>
                        </Item>
                        <Item key="/admin/buy/invoice">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/buy/invoice?type=0") }>
                                { Tr("add81") }
                            </a>
                        </Item>
                        <Item key="/admin/buy/userlist">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/buy/userlist?type=0") }>
                                { Tr("add8") }
                            </a>
                        </Item>
                        <Item key="/admin/buy/userlist2">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/buy/userlist2?type=0&big=1") }>
                                { Tr("recircle25") }
                            </a>
                        </Item>
                    </SubMenu>
                    <SubMenu key="/admin/sell"
                             title={<span><i className="icon iconfont icon-dingdan"></i> <span>{ Tr("recircle14") }</span></span>}>
                        <Item key="/admin/sell/order">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/sell/order?type=1") }>
                                { Tr("adminLeftMenu17") }
                            </a>
                        </Item>
                        <Item key="/admin/sell/sale">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/sell/sale?type=1") }>
                                { Tr("recircle32") }
                            </a>
                        </Item>
                        <Item key="/admin/sell/invoice">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/sell/invoice?type=1") }>
                                { Tr("add81") }
                            </a>
                        </Item>
                        <Item key="/admin/sell/userlist">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/sell/userlist?type=1") }>
                                { Tr("add8") }
                            </a>
                        </Item>
                        <Item key="/admin/sell/userlist2">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/sell/userlist2?type=1&big=1") }>
                                { Tr("recircle25") }
                            </a>
                        </Item>
                    </SubMenu>
                    {/*<Item key="/admin/order" onClick={ ()=>this.setState({ openKeys: [] }) }>*/}
                    {/*    <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/order/list`, 'ORDER') }>*/}
                    {/*        <i className="icon iconfont icon-dingdan"> </i>*/}
                    {/*        <span>{ Tr("adminLeftMenu17") }</span>*/}
                    {/*    </a>*/}
                    {/*</Item>*/}
                    {/*<Item key="/admin/invoice"  onClick={ ()=>this.setState({ openKeys: [] }) }>*/}
                    {/*    <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/invoice/list`) }>*/}
                    {/*        <i className="icon iconfont icon-fapiao1"> </i>*/}
                    {/*        <span>{ Tr("add81") }</span>*/}
                    {/*    </a>*/}
                    {/*</Item>*/}

                    <SubMenu key="turnover" title={<span><i
                        className="icon iconfont icon-jine1"> </i><span>{ Tr("bill1") }</span></span>}>
                        <Item key="/admin/turnover/date">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/turnover/date`, 'DAILY_TURNOVER') }>
                                { Tr("bill2") }
                            </a>
                        </Item>
                        <Item key="/admin/turnover/month">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/turnover/month`, 'MONTHLY_TURNOVER') }>
                                { Tr("bill3") }
                            </a>
                        </Item>
                    </SubMenu>

                    {/*<SubMenu key="user" title={<span><i*/}
                    {/*    className="icon iconfont icon-yuangongliebiao"> </i><span>{ Tr("add7") }</span></span>}>*/}
                    {/*    <Item key="/admin/userlist">*/}
                    {/*        <a href="javascript:;" onClick={ (e)=>{*/}
                    {/*            window.clearUserQuery && window.clearUserQuery();*/}
                    {/*            this.gotoPage(e, `/admin/userlist`, 'USER_LIST');*/}
                    {/*        } }>*/}
                    {/*            { Tr("add8") }*/}
                    {/*        </a>*/}
                    {/*    </Item>*/}
                    {/*    <Item key="/admin/template">*/}
                    {/*        <Link to="/admin/template">*/}
                    {/*            { Tr("add83") }*/}
                    {/*        </Link>*/}
                    {/*    </Item>*/}
                    {/*    <Item key="/admin/push">*/}
                    {/*        <Link to="/admin/push">*/}
                    {/*            { Tr("add84") }*/}
                    {/*        </Link>*/}
                    {/*    </Item>*/}
                    {/*    /!*<Item key="/admin/user/report">*!/*/}
                    {/*    /!*    <Link to="/admin/user/report">*!/*/}
                    {/*    /!*        { Tr("add9") }*!/*/}
                    {/*    /!*    </Link>*!/*/}
                    {/*    /!*</Item>*!/*/}
                    {/*</SubMenu>*/}
                    <Item key="/admin/com">
                        <ComSetting>
                            <a href="javascript:;" style={{ color: 'rgba(255, 255, 255, 0.65)' }}>
                                <i className="icon iconfont icon-com001"> </i>
                                <span>{ Tr("recircle10") }</span>
                            </a>
                        </ComSetting>
                    </Item>
                </Menu>
            </div>
        );
    }
}
