export default {
    login1: "el número de cuenta y la contraseña no pueden estar vacíos",
    login2: "iniciar sesión ...",
    login3: "Entrar",
    login4: "ingrese un número de cuenta",
    login5: "ingrese su número comercial",
    login6: "Ingrese su contraseña original",
    login7: "recordar cuenta",
    login8: "¿olvidó su contraseña?",
    login9: "¿Todavía no tienes cuanta?. Regístrate",

    register1: "españa",
    register3: "¡error al obtener datos comerciales!",
    register4: "¡error al obtener datos de pago!",
    register5: "¡error al obtener los datos de dirección!",
    register6: "¡no se encontró la ubicación seleccionada!",
    register7: "seleccione la dirección primero",
    register8: "error al cargar, inténtalo de nuevo",
    register9: "el registro se realizó correctamente ",
    register10: "registrar una cuenta",
    register11: "cuenta de inicio de sesión (obligatorio)",
    register12: "contraseña (obligatorio)",
    register13: "confirmar contraseña (obligatorio)",
    register14: "dirección de correo electrónico (obligatorio)",
    registro15: "principal (al menos uno)",
    register16: "Método de pago",
    register17: "nombre del departamento",
    register18: "contacto",
    register19: "teléfono de contacto",
    register20: "consumo per cápita (€)",
    register21: "rango de entrega (km)",
    register22: "dirección (obligatorio)",
    register23: "por favor seleccione ...",
    register24: "dirección",
    register25: "ingrese la dirección (calle)",
    registro26: "por favor ingrese cif",
    register27: "subir imagen",
    register28: "imagen de fondo",
    register29: "la imagen de fondo después de la carga se utilizará en la página principal de la tienda",
    register30: "logo tienda",
    register31: "subir tamaño del logotipo de la tienda: 1: 1",
    register32: "registrarse",
    register33: "¿hay una cuenta? inicie sesión ahora",
    register34: "por favor, elija un idioma",
    register35: "Después de guardar, no se puede modificar.",
    register36: "Solicite la modificación al desarrollador del sistema.",

    index1: "recibo dentro de los 7 días",
    index2: "lista de ventas de productos",
    index3: "partes",
    index4: "resumen de ayer",
    index5: "resumen de esta semana",
    index6: "resumen de este mes",
    index7: "número de pedidos",
    index8: "facturación",
    index9: "resumen de hoy",
    index10: "pedido en curso",
    index11: "pedido completado",

    leftMenu1: "desea salir",
    leftMenu2: "¿desea cerrar sesión y salir?",
    leftMenu5: "pedido",
    leftMenu6: "mesa",
    leftMenu7: "informe",
    leftMenu8: "imprimir",

    choice1: "tiene un nuevo pedido",
    choice2: "haga clic para ver",
    choice3: "tiene un nuevo pedido",
    choice4: "pedido de la mesa",
    choice5: "nuevo pedido para llevar",
    choice6: "haga clic para ver el nuevo pedido para llevar",
    choice7: "¡error al obtener información del pedido!",
    choice8: "¡error al obtener método de pago!",
    choice9: "¡por favor, elija platos!",
    choice10: "¡orden exitosa!",
    choice11: "confirmar ",
    choice12: "¿confirma el reembolso?",
    choice13: "volver a un pedido exitoso",
    choice14: "¡pago exitoso!",
    choice15: "no hay datos pendientes",
    choice16: "confirmar ",
    choice17: "si se eliminan los datos del pedido pendiente, no se podrán recuperar. ¿está seguro de eliminar?",
    choice18: "solicitando ...",
    choice19: "¡operación exitosa!",
    choice20: "¡error al reproducir el estado del sonido!",
    choice21: "al salir se guardará automáticamente el pedido, ¿está seguro de salir?",
    choice22: "Pedido",
    choice23: "mesa",
    choice24: "Nº personas",
    choice25: "observaciones",
    choice26: "ingrese una nota",
    choice27: "Para llevar",
    choice28: "sí",
    choice29: "no",
    choice30: "Descuento",
    choice31: "total",
    choice32: "Pedido",
    choice33: "Cobrar",
    choice34: "Anular",
    choice35: "pedidos pendientes",
    choice36: "tomar orden",
    choice37: "Para llevar",
    choice38: "completado",
    choice39: "número de pedido",
    choice40: "no",
    choice41: "beber",
    choice42: "Fecha",
    choice43: "pedido",
    choice44: "descuento",
    choice45: "Preparar",
    choice46: "Enviar",
    choice47: "Entregado",
    choice48: "cambio",
    choice49: "confirmar pedido",
    choice50: "tiempo de pedido pendiente",
    choice51: "cantidad",
    choice52: "operación",
    choice53: "eliminar",
    choice54: "sin datos pendientes",
    choice55: "cambiar mesa",
    choice56: "su navegador no admite la etiqueta de audio",
    choice57: "A domicilio",
    choice58: "Guardar",
    choice61: "Elige un ciclista",
    choice62: "regreso",
    choice63: "Confirmar el envío",
    choice64: "Elige un ciclista",
    choice65: "Piloto asignado correctamente",

    changeTab1: "¡actual es la primera página!",
    changeTab2: "¡actual es la última página!",
    changeTab3: "seleccione el número de mesa que desea",
    changeTab4: "cambiar mesa",
    changeTab5: "número de mesa actual",
    changeTab6: "cambiar mesa",
    changeTab7: "confirmar accesos directos",
    changeTab8: "no hay mesas",
    changeTab9: "inactivo",
    changeTab10: "en uso",
    changeTab11: "cancelar",
    changeTab12: "determinar",
    changeTab13: "Seleccione el número de tabla",
    changeTab14: "Seleccione el número de la mesa de citas, el sistema lo mostrará durante la cita.",

    choiceTabs1: "mesas",
    choiceTabs2: "barra",
    choiceTabs3: "Para llevar",
    choiceTabs4: "Completados",

    completeted1: "no hay pedido completado",
    completeted2: "número de pedido",

    deskNo1: "todos",
    deskNo2: "ya facturado",
    deskNo3: "bloqueado",
    deskNo4: "no hay mesa",
    deskNo5: "persona",

    goods1: 'Nombre',
    goods2: 'tipo de producto',
    goods3: '¡error al obtener la categoría del producto!',
    goods4: '¡error al obtener la lista de producro! ',
    goods5: 'ingrese el nombre o el número del plato',
    goods6: 'no hay bienes elegibles',

    goodsKind1: "",

    noDesk1: "minutos",
    noDesk2: "no hay mesa en este momento",
    noDesk3: "buscar el nombre del plato",

    pickChange1: "¡ingrese el monto pagado!",
    pickChange2: "cantidad insuficiente",
    pickChange3: "recibido",
    pickChange4: "aceptable",
    pickChange5: "cambio ",
    pickChange6: "imprimir un ticket pequeño",
    pickChange7: "atención",
    pickChange8: "¿confirmas el pago?",

    shopList1: "¿está seguro de eliminarlo?",
    shopList2: "ingrese el motivo de la devolución ",
    shopList3: "Descripcion",
    shopList4: "Pre.",
    shopList5: "Cant.",
    shopList6: "Suma",
    shopList7: "sin comida",
    shopList8: "agregar comida",

    takeaway1: "sin pedido",
    takeaway2: "envío domicilio",
    takeaway3: "solo recogida en local",
    takeaway4: "¡obteniendo el error de estado de comida para llevar!",
    takeaway5: "el sonido está desactivado",
    takeaway6: "el sonido está activado",
    takeaway7: "no hay pedido para llevar",

    payStatusUn: "no pagado",
    payStatusPart: "pago fraccionado",
    payStatusPay: "pagado",
    payStatusPartRefund: "reembolso parcial",
    payStatusRefund: "reembolsado",

    orderStatus1: "Nuevo",
    orderStatus2: "Preparando",
    orderStatus3: "En camino",
    orderStatus4: "Entregado",
    orderStatus5: "completado",
    orderStatus6: "cerrado",
    orderStatus7: "anular suscripción",

    businessHours1: "lunes",
    businessHours2: "martes",
    businessHours3: "miércoles",
    businessHours4: "jueves",
    businessHours5: "viernes",
    businessHours6: "sábado",
    businessHours7: "domingo",
    businessHours8: "¡error de horario comercial!",
    businessHours9: "¡error al guardar!",
    businessHours10: "guardando ...",
    businessHours11: "¡guardado correctamente!",
    businessHours12: "configuración del horario comercial",
    businessHours13: "am",
    businessHours14: "tarde",
    businessHours15: "descanso",

    discountEdit1: "Ingrese el valor mínimo de uso",
    discountEdit2: "ingrese un descuento",
    discountEdit3: "gestión de descuentos",
    discountEdit4: "modificar descuento",
    discountEdit5: "agregar descuento",
    discountEdit6: "tipo de descuento",
    discountEdit7: "total descontado",
    discountEdit8: "descuento proporcional",
    discountEdit9: "Valor mínimo",
    discountEdit10: "importe de descuento",
    discountEdit11: "ratio de descuento",
    discountEdit12: "ingrese un monto de descuento",
    discountEdit13: "ingrese la tasa de descuento (1-100)",
    discountEdit14: "Gestión de sitios web",

    discountList1: "eliminar con éxito",
    discountList2: "¡error al modificar el estado de la oferta!",
    discountList3: "¡modificado el estado de la oferta exitosamente!",
    discountList4: "¡error al obtener datos de método de pago!",
    discountList5: "error al obtener información de la tienda",
    discountList6: "¡modificado correctamente!",
    discountList7: "¡error al obtener el estado de comida para llevar!",
    discountList8: "gestión de comida para llevar",
    discountList9: "Configuración del servicio para llevar",
    discountList10: "Habilitar servicio para llevar",
    discountList11: "Habilitar entrega",
    discountList12: "configuración básica",
    discountList13: "método de pago (para llevar)",
    discountList14: "pedido mínimo (€)",
    discountList15: "zona de reparto",
    discountList16: "distancia",
    discountList17: "gastos de envio",
    discountList18: "distancia",
    discountList19: "agregar configuración de tarifa de envío",
    discountList20: "área de distribución",
    discountList21: "agregar un descuento",
    discountList22: "cantidad / porcentaje de descuento",
    discountList23: "gestión",
    discountList24: "congelar",
    discountList25: "descongelar",
    discountList26: "sin datos de descuento",
    discountList27: "complete el código del área",
    discountList28: "descuento efectivo",
    discountList29: "descuento porcentaje",
    discountList30: "normal",
    discountList31: "Habilitar, Sobre nosotros",
    discountList32: "No ha configurado el pago, póngase en contacto con el administrador del sistema",
    discountList33: "Asignar ciclista",
    discountList34: "Después de cerrar la entrega, el sitio web mostrará el siguiente texto",
    discountList35: "Dominio",
    discountList36: "Añadir",
    discountList37: "Imágenes de desplazamiento de la página de inicio",
    discountList38: "Sin datos",

    bannerEdit1: "Agregar gráfico de desplazamiento",
    bannerEdit2: "Editar gráfico de desplazamiento",
    bannerEdit3: "nombre",
    bannerEdit4: "Por favor ingrese un nombre",
    bannerEdit5: "tiempo de visualización",
    bannerEdit6: "largo",
    bannerEdit7: "imagen",
    bannerEdit8: "dirección de enlace",
    bannerEdit9: "Ingrese la dirección del enlace",
    bannerEdit10: "redirigir a",
    bannerEdit11: "Página de categoría",
    bannerEdit12: "Producto único",
    bannerEdit13: "Por favor ingrese un nombre",
    bannerEdit14: "Seleccione el tiempo de visualización",
    bannerEdit15: "Sube una foto",
    bannerEdit16: "Tiempo de empezar",
    bannerEdit17: "Hora de finalización",
    bannerEdit18: "Eliminar",
    bannerEdit19: "Modificar el estado de la imagen con éxito",
    bannerEdit20: "Tamaño recomendado",
    bannerEdit21: "Utilizando",
    bannerEdit22: "no ha comenzado",
    bannerEdit23: "encima",
    bannerEdit24: "¿Estás seguro de eliminar esta imagen?",
    bannerEdit25: "si",
    bannerEdit26: "No",
    goodsEdit1: "referencia",
    goodsEdit2: "nombre",
    goodsEdit3: "¡error al obtener detalles del producto!",
    goodsEdit4: "¡error al obtener información de la unidad!",
    goodsEdit5: "¡error al obtener información de clasificación del producto!",
    goodsEdit6: "no se pudo obtener información sobre alérgenos",
    goodsEdit7: "no se pudo obtener información del atributo del producto",
    goodsEdit8: "¿está seguro de eliminar los platos?",
    goodsEdit9: "si se elimina la guarnición, no se recuperará. ¿desea confirmar la eliminación?",
    goodsEdit10: "edición de productos",
    goodsEdit11: "Añadir",
    goodsEdit12: "nombre",
    goodsEdit13: "ingrese el nombre del producto",
    goodsEdit14: "refrencia",
    goodsEdit15: "ingrese el número de artículo",
    goodsEdit16: "Imagen",
    goodsEdit17: "precio",
    goodsEdit18: "ingrese el precio del producto",
    goodsEdit19: "P.de llevar",
    goodsEdit20: "ingrese el precio de comida para llevar",
    goodsEdit21: "unidad",
    goodsEdit22: "ordenar",
    goodsEdit23: "por favor ingrese el orden de prioridad",
    goodsEdit24: "Ctegoría",
    goodsEdit25: "descripción",
    goodsEdit26: "tipo",
    goodsEdit27: "general",
    goodsEdit28: "paquete",
    goodsEdit29: "el front end no podrá mostrar ni pedir comida después de que el producto esté desactivado",
    goodsEdit30: "características del producto",
    goodsEdit31: "alérgenos",
    goodsEdit32: "agregar un nombre de guarnición",
    goodsEdit33: "eliminar la guarnición",
    goodsEdit34: "número de opciones",
    goodsEdit35: "un plato",
    goodsEdit36: "elija una guarnición",
    goodsEdit37: "ingrese un nombre o número",
    goodsEdit38: "agregar una guarnición",
    goodsEdit39: "Orden",
    goodList1: "falló la modificación del estado del producto",
    goodList2: "¡estado modificado exitosamente!",
    goodList3: "¡falló la ordenación!",
    goodList4: "¡seleccione el archivo primero!",
    goodList5: "error en el elemento recomendado",
    goodList6: "¡recomendación aceptada!",
    goodList7: "¡falló la cancelación de los elementos recomendados!",
    goodList8: "¡productos recomendados cancelados exitosamente!",
    goodList9: "restaurante",
    goodList10: "todos los restaurantes",
    goodList11: "Categoría",
    goodList12: "Nombre",
    goodList13: "Ordenar",
    goodList14: "Importar",
    goodList15: "descargar plantilla",
    goodList16: "recomendado",
    goodList17: "unidad",
    goodList18: "no recomendado",
    goodList19: "recomendado",
    goodList20: "cancelar Recomendar",
    goodList21: "seleccionar archivo",
    goodList22: "P.de llevar",
    goodList23: "P.de salón",
    propEdit1: "lista de Atributos",
    propEdit2: "modificar característica",
    propEdit3: "Agregar atributo",
    propEdit4: "nombre de Atributo",
    propEdit5: "Ingresa el nombre del atributo",
    propEdit6: "agregar Atributo",
    propList1: "lista de atributos del producto",
    propList2: "sin atributos",
    goodsKindsEdit1: "editar categoría",
    goodsKindsEdit2: "agregar categoría",
    goodsKindsEdit3: "Nombre",
    goodsKindsEdit4: "ingrese un nombre de categoría",
    goodsKindsEdit5: "el sistema no podrá mostrar todos los artículos de esta categoría después de que el producto esté deshabilitado",
    goodsKindsList1: "si se elimina la categoría del artículo, no se puedrá recuperar, ¿esta seguro que desea eliminar?",
    goodsKindsList2: "¡error al modificar!",
    goodsKindsList3: "¡se ha modificado con éxito!",
    goodsKindsList4: "la categoría no existe",
    adminLeftMenu1: "Productos",
    adminLeftMenu2: "lista de productos",
    adminLeftMenu3: "Categoría",
    adminLeftMenu4: "Atributos",
    adminLeftMenu5: "Enpleados",
    adminLeftMenu6: "lista de empleados",
    adminLeftMenu7: "gestión de roles",
    adminLeftMenu8: "gestión de publicaciones",
    adminLeftMenu9: "Tienda",
    adminLeftMenu10: "Para llevar",
    adminLeftMenu11: "sobre nosotros",
    adminLeftMenu12: "Información",
    adminLeftMenu13: "Horario",
    adminLeftMenu14: "Mesas",
    adminLeftMenu15: "configuración básica",
    adminLeftMenu16: "cambiar contraseña",
    adminLeftMenu17: "Pedidos",
    orderList1: "obtener detalles ...",
    orderList2: "teléfono del usuario",
    orderList3: "hasta",
    orderList4: "Tipo",
    orderList5: "Estado",
    orderList6: "Estado",
    orderList7: "Pedido",
    orderList8: "Dirección",
    orderList9: "teléfono de contacto",
    orderList10: "número de copias",
    orderList11: "Total",
    orderList12: "ver",
    orderList13: "sin pedido",
    orderList14: "detalles del pedido",
    orderList15: "mesa",
    orderList16: "contacto",
    orderList17: "teléfono",
    orderList18: "información de pago",
    orderList19: "información de programación",
    orderList20: "detalles del pedido",
    orderList21: "total",
    orderList22: "notas del cliente",
    orderList23: "Eliminar",
    orderList24: "Confirmar eliminación",
    orderList25: "eliminado con éxito",
    postEdit1: "lista de publicaciones",
    postEdit2: "modificación posterior",
    postEdit3: "agregar una publicación",
    postEdit4: "número de publicación",
    postEdit5: "código",
    postEdit6: "nombre de la publicación",
    postEdit7: "ingrese el nombre del trabajo",
    postList1: "sin publicación",
    printer1: "establecer el error de sonido",
    printer2: "Habilitar sonido",
    printer3: "configuración de impresión",
    printer4: "pedido para imprimir automáticamente",
    printer5: "la comprobación se imprime automáticamente",
    printer6: "imprimir automáticamente al añadir plato",
    printer7: "pedido total",
    printer8: "ticket pequeño 1",
    printer9: " ticket pequeño 2",
    printer10: " ticket pequeño 3",
    printer11: " ticket pequeño 4",
    printer12: "¡error al obtener configuración de la impresora!",
    printer13: "número de copias",
    printer14: "seleccione la plantilla de impresión",
    printer15: "seleccionar plantilla",
    printer16: "Impresión de comida en la Sala",
    printer17: "Para llevar",
    printer18: "Código QR",
    printer19: "pedido para llevar",
    printer20: "Barra",
    printer21: "Cocina",
    printer22: "otro",
    printer23: "número",
    printer24: "añadiendo ...",
    printer25: "añadido exitosamente",
    printer26: "modificar el nombre de la impresora correctamente",
    printer27: "¿está seguro de eliminar esta impresora?",
    printer28: "eliminar ...",
    printer29: "enviado ...",
    printer30: "¡modifique la plantilla con éxito!",
    printer31: "¡guardado correctamente!",
    printer32: "impresora establecida exitosamente ",
    printer33: "lista de impresoras",
    printer34: "modificar el nombre",
    printer35: "agregar una impresora",
    printer36: "configuración de impresión",
    printer37: "Nombre",
    printer38: "configuración",
    printer39: "configuración de la plantilla de impresión",
    printer40: "Nombre",
    printer41: "guardar configuración",
    printer42: "modificar nombre de impresora",
    printer43: "tipo de impresora",
    printer44: "impresora local",
    printer45: "impresora de red",
    printer46: "seleccionar impresora",
    printer47: "nombre de impresora",
    printer48: "codificación",
    printer49: "clave",
    printer50: "datos gprs",
    printer51: "seleccionar contenido de impresión de ticket pequeño",
    printer52: "información del encabezado",
    printer53: "nombre del restaurante",
    printer54: "nombre de la calle",
    printer55: "teléfono del restaurante",
    printer56: "número de impuesto de restaurante",
    printer57: "fecha",
    printer58: "información del ticket",
    otherPrinter1: 'Error al guardar',
    otherPrinter2: 'Guardado exitosamente',
    otherPrinter3: 'Imprimir catálogo',
    otherPrinter4: 'nombre',
    otherPrinter5: 'operando',
    otherPrinter6: 'Pago diario',
    otherPrinter7: 'Imprime automáticamente al finalizar la compra',
    otherPrinter8: 'Incluir lista de pedidos',
    otherPrinter9: 'Guardar ajustes',
    roleEdit1: "lista de roles",
    roleEdit2: "modificación de roles",
    roleEdit3: "agregar un rol",
    roleEdit4: "nombre de rol",
    roleEdit5: "ingrese un nombre de rol",
    roleEdit6: "edición de permisos",
    roleList1: "sin rol",
    staffEdit1: "error al obtener la lista de trabajos",
    staffEdit2: "error al obtener la lista de roles",
    staffEdit3: "espere a que se cargue la imagen",
    staffEdit4: "modificación",
    staffEdit5: "agregar empleado",
    staffEdit6: "nombre de inicio de sesión",
    staffEdit7: "ingrese su nombre de usuario",
    staffEdit8: "Nombre",
    staffEdit9: "Ingrese el nombre",
    staffEdit10: "identificación del empleado",
    staffEdit11: "ingrese el número de identificación del empleado",
    staffEdit12: "Teléfono",
    staffEdit13: "ingrese el teléfono del empleado",
    staffEdit14: "Contraseña",
    staffEdit15: "ingrese su contraseña de inicio de sesión",
    staffEdit16: "la contraseña debe tener al menos 6 dígitos y debe contener, números, letras y caracteres especiales (no puede repetir 4 veces consecutivas dicho caracter) ",
    staffEdit17: "avatar",
    staffEdit18: "rol",
    staffEdit19: "post",
    staffEdit20: "usar lenguaje",
    staffEdit21: "jinete",
    staffList1: "falló la modificación del estado del empleado",
    staffList2: "éxito la modificación del estado del empleado",
    staffList3: "Teléfono",
    staffList4: "Ingrese el teléfono",
    staffList5: "Cambiar contraseña",
    staffList6: "sin datos del empleado",
    updatePwd1: "la modificación se realizó correctamente, ¡vuelva a iniciar sesión!",
    updatePwd2: "contraseña original",
    updatePwd3: "nueva contraseña",
    updatePwd4: "confirmar contraseña",
    updatePwd5: "confirme la contraseña",
    aboutus1: "reiniciar sesión",
    aboutus2: "sobre nosotros",
    editor1: "establecer título",
    editor2: "cuerpo",
    editor3: "texto de enlace",
    editor4: "enlace",
    editor5: "subir imagen",
    editor6: "cargar",
    editor7: "crear",
    editor8: "tamaño de fuente",
    editor9: "fuente",
    editor10: "宋体",
    editor11: "microsoft yahei",
    editor12: "color del texto",
    editor13: "lista de configuración",
    editor14: "lista ordenada",
    editor15: "lista desordenada",
    editor16: "alineación",
    editor17: "hacia la izquierda",
    editor18: "centrado",
    editor19: "hacia la derecha",
    editor20: "insertar video",
    editor21: "formato como",
    storeEdit1: "error al obtener datos de servicio",
    storeEdit2: "modificación de datos",
    storeEdit3: "nombre de la tienda",
    storeEdit4: "el servicio incluye",
    storeEdit5: "imagen de fondo de la aplicación",
    storeEdit6: "la imagen de fondo después de la carga se utilizará en la página de inicio de la tienda de aplicaciones",
    storeEdit7: "banner (página web) ",
    storeEdit8: "la imagen de fondo después de la carga se usará para la página de inicio",
    storeEdit9: "código qr de empresa",
    storeEdit10: "escanea el código qr, para añadir el local a tus favoritos ",
    storeList1: "lista de tiendas",
    storeList2: "ingrese el nombre del restaurante",
    storeList3: "ingrese su teléfono",
    storeList4: "tiempo de registro",
    storeList5: "dirección del restaurante",
    storeList6: "no hay restaurante",
    tableManage1: "¡seleccione una región primero!",
    tableManage2: "nueva área",
    tableManage3: "agregar una mesa",
    addArea1: "agregar zona exitosa",
    addArea2: "si elimina el área no se podrán restablecer los cambios, ¿esta seguro de eliminar la zona?",
    addArea3: "¡zona eliminada exitosamente!",
    addArea4: "modificar área",
    addArea5: "agregar una nueva área",
    addArea6: "nombre del área",
    addArea7: "organizar prioridad",
    addArea8: "Cuanto mayor sea el número, se colocará más arriba ",
    addArea9: "código qr de la mesa",
    addArea10: "imprime el código qr de todas las mesas de una zona",
    addArea11: "Guardar",
    addDesk1: "modificar la mesa",
    addDesk2: "agregar una mesa",
    addDesk3: "Número",
    addDesk4: "el número de personas que pueden sentarse",
    addDesk5: "código qr de la mesa",
    addDesk6: "imprime el código qr de la mesa",
    download: "descargar",
    print: "imprimir",
    name: "nombre",
    time: "Fecha",
    delConfirm: "no se puede restaurar después de la eliminación, ¿está seguro?",
    no: "Serie",
    checkAll: "Todo",
    all: "todos",
    goods: "bienes",
    enable: "Habilitado",
    disable: "deshabilitar",
    upload: "subir",
    uploadTips: "error al carga, inténtalo de nuevo",
    uploadFail: "falló la carga",
    uploadSuccess: "¡subido con éxito!",
    uploading: "subiendo ...",
    tips: "sugerencia",
    saveSuccess: "¡guardado correctamente!",
    status: "estado",
    edit: "modificar",
    edit2: "editar",
    save: "guardar",
    sure: "ok",
    cancel: "cancelar",
    searching: "consultando ...",
    search: "buscar",
    delete: "eliminar",
    loginOut: "salir",
    goodsAttributes1: "¡Primero seleccione los atributos de los platos!",
    goodsAttributes2: "Seleccionar paquete",
    goodsAttributes3: "Seleccione",
    goodsAttributes4: "Ud",
    goodsAttributes5: "Seleccione los atributos ",
    goodsAttributes6: "Modificar",
    goodsAttributes7: "Introducir cantidad",
    goodsAttributes8: "Modificar el número de platos",
    goodsAttributes9: "Confirmar cambios",
    goodsAttributes10: "Sumar",
    goodsAttributes11: "Restar",
    goodsAttributes12: "Seleccione el número de platos",
    passwordTips: "La contraseña solo puede tener 4 dígitos",
    conTactEmail: '¿Olvidó su número?. Contactenos a info@g-eat.es',
    enter: "Entrar",
    inputTips: 'Ingrese el "Nº Comerciante" que le envió "Goeat"',
    remberPwd: "Recordar contraseña",
    inputNumber: "Ingrese su NºComerciante",
    inputNumberAll: "¡Ingrese el Nº Comerciante completo!",
    loginTips: 'Seleccione una cuenta para iniciar sesión e ingrese la contraseña',
    serachuser: 'Consultando usuarios ...',
    regsuccess: 'Registro exitoso',
    regsuccesstips: 'Felicitaciones, ha registrado con éxito el cliente" Ximi Mission "',
    storeNoIs: "Su número de negocio es",
    loginNow: "Iniciar sesión ahora",
    freeReg: "Registro gratuito",
    email: "Correo electrónico",
    emailUseful: 'Recibir por correo \" Nº Comerciante \ "y \" recuperar contraseña \ "',
    loginPwd: "Contraseña para iniciar sesión",
    inoutPwdNum: "Ingrese cuatro dígitos",
    inoutPwdAgain: "Ingrese la contraseña nuevamente",
    countryprovince: "Provincia del país",
    country:"País",
    province: "Provincia",
    inputAddress: "Ingrese la calle y el número ",
    inputYoubian: "Complete el código postal",
    youbian: "Código postal",
    inputCityName: "Complete el nombre de la ciudad",
    cityName: "Ciudad",
    storenum: "Nº Comerciante",
    price: 'Precio',
    goodstax: 'IVA',
    goodstaxtips: 'Introduzca el IVA',
    ifTakeway: 'Para llevar',
    is: 'Sí',
    not: 'No',
    taxonlynumber: 'IVA solo puede ser número',
    basicManange: "Gestión básica",
    takeawayuser1: "¡Usuarios agregados correctamente!",
    takeawayuser2: "Confirmar selección",
    takeawayuser3: "Agregar usuario",
    takeawayuser4: "Nombre",
    takeawayuser5: "calle",
    takeawayuser6: "Número de calle",
    takeawayuser7: "Unidad",
    takeawayuser8: "Escaleras",
    takeawayuser9: "Piso",
    takeawayuser10: "Número de casa",
    takeawayuser11: "Código postal",
    takeawayuser12: "Género",
    takeawayuser13: "Hombre",
    takeawayuser14: "Mujer",
    takeawayuser15: "Buscar usuarios existentes",
    takeawayuser16: "No",
    takeawayuser17: "Resultados de la búsqueda",
    editNumNotZero: "El número de platos no puede ser cero",
    bill1: "Facturación",
    bill2: "Facturación diaria",
    bill3: "Facturación mensual",
    bill4: "Cantidad de pedido",
    bill5: "Cantidad de pedido",
    bill6: "facturación",
    bill7: "Efectivo",
    bill8: "Tarjeta de crédito",
    bill9: "Otro",
    bill10: "Pagar",
    bill11: "Hora de salida",
    bill12: "Lista ",
    bill13: "Lista ",
    bill14: "Seleccione una fecha",
    bill15: "Aún no hay datos",
    bill16: "Pago del día",
    bill17: "Imprimir ",
    bill18: "No facturado actualmente",
    bill19: "Pago exitoso",
    bill20: "Ingrese el cambio de reserva",
    bill21: "Ingresó exitosamente el cambio de la reserva",
    bill22: "Número de pedido",
    bill23: "número de mesa / teléfono",
    bill24: "Cliente",
    bill25: "Número de personas",
    bill26: "Cantidad",
    bill27: "Método de pago",
    bill28: "Cajero",
    bill29: "Hora de salida",
    bill30: "Venta",
    bill31: "Cheque",
    bill32: "Cantidad total",
    bill33: "Disponibilidad de caja registradora",
    bill34: "Listo para el cambio",
    bill35: "Gastos",
    bill36: "Gastos totales",
    bill37: "Resumen",
    bill38: "caja registradora en efectivo",
    bill39: "Imprimir automáticamente al pagar",
    bill40: "Horario",
    bill41: "Finalizar compra",
    bill42: "Hora de salida",
    bill43: "Aún no facturado",
    bill44: "Confirmar el pago",
    bill45: "Cambio de reserva",
    bill46: "Introducir la cantidad reservada",
    bill47: "Listo para el cambio",
    bill48: "Imprimir ",
    bill49: "Principal (solo uno)",
    saleProductDay:"Hoy",
    saleProductWeek:"Semana",
    saleProductMonth:"Mayo",
    saleProductAll:"Todo",
    noSaleRangeData:"Sin datos",
    getLocationLoading: "Obtener ubicación",
    getLocationFailure: "Error al obtener la ubicación",
    selectPrinterModel: "Seleccione un modelo de impresora",
    printerModel: "Modelo de impresora",
    openChash: "Cajon ",
    noOpenChashPer: "Sin permiso. Póngase en contacto con el administrador",
    changePrice: 'Cambiar precio',
    editPriceNotZero: "Precio de los platos",
    printByStep: "Impresión segmentada",
    payCardSetting1: "Configuración de pago",
    payCardSetting2: "Método de pago",
    payCardSetting3: "Fecha de inicio",
    payCardSetting4: "Importe",
    payCardSetting5: "Impuestos incluidos",
    payCardSetting6: "Impuestos no incluidos",
    payCardSetting7: "Agregar información de tarjeta bancaria",
    payCardSetting8: "Número de tarjeta bancaria",
    payCardSetting9: "Fecha de vencimiento",
    payCardSetting10: "Código de seguridad",
    payCardSetting11: "Titular",
    payCardSetting12: "goeat no guardará la información de su tarjeta de crédito",
    payCardSetting13: "acuerdo de pago de cabras",
    payCardSetting14: "He leído el acuerdo de deducción automática de la empresa geat",
    payCardSetting15: "1 mes",
    payCardSetting16: "3 meses",
    payCardSetting17: "Medio año (6 meses)",
    payCardSetting18: "1 año (12 meses)",
    payCardSetting19: "2 años (24 meses)",
    payCardSetting20: "Caja",
    payCardSetting21: "Web para llevar",
    payCardSetting22: "App de usuario",
    payCardSetting23: "App de comerciante",
    payCardSetting24: "QR para realizar un pedido",
    payCardSetting25: "En uso",
    payCardSetting26: "No abierto",
    payCardSetting27: "Solicitar activación",
    payCardSetting28: "Puede utilizar los siguientes servicios",
    payCardSetting29: "Esta función aún no está abierta",
    payCardSetting30: "¡Lea y acepte primero el acuerdo de deducción!",
    payCardSetting31: "¡Complete la información de la tarjeta bancaria!",
    payCardSetting32: "Modificar la información de la tarjeta bancaria",
    payCardSetting33: "Confirmar",
    payCardSetting34: 'volver a la última página',
    payCardSetting35: "Agregar tarjeta de crédito",
    payCardSetting36: "Modificar tarjeta de crédito",
    payCardSetting37: "Si necesita cancelar la autorización, comuníquese con goeat",
    payCardSetting38: "He leído la empresa geat",
    payCardSetting39: "Acuerdo de deducción automática",
    payCardSetting40: "Ciclo de pago",
    payCardSetting41: "mes",
    payCardSetting42: "fecha de inicio",
    payCardSetting43: "Monto del pago",
    payCardSetting44: "Impuesto incluido",
    payCardSetting45: "Excluyendo impuestos",
    payCardSetting46: "Pedidos de ipad",
    payCardSetting47: "Agregar tarjeta bancaria",
    payCardSetting48: "No ha agregado ninguna información de tarjeta de crédito",
    payCardSetting49: "Agregar tarjeta de crédito",
    payCardSetting50: "Estado actual",
    payCardSetting51: "Registro histórico",
    payCardSetting52: "Fecha de orden",
    payCardSetting53: "Número de orden",
    payCardSetting54: "Nombre del titular de la tarjeta",
    payCardSetting55: "Método de pago",
    payCardSetting56: "Cantidad",
    payCardSetting57: "Fecha de pago",
    payCardSetting60: "Ha configurado correctamente el pago con tarjeta de crédito",
    payCardSetting61: "Nombre del tarjetahabiente",
    payCardSetting62: "Cancelar el pago",
    bookOrder1: "Pedido de libros",
    bookOrder2: "Tiene un nuevo reserva",
    siteKeyWords: "Buscar la palabra clave",
    keywords: "Palabras clave",
    description: "Descripción",
    startNav: "Iniciar navegación",
    openBook: "Activar reserva",
    showType: "Método de visualización de mercancías",
    showList: "Lista, imagen pequeña",
    showImg: "Cuadro grande",
    article1: "Gestión de artículos",
    article2: "Agregar una nueva solución",
    article3: "Título del proyecto",
    article4: "Última fecha guardada",
    article5: "Publicar",
    article6: "Título",
    add1: "El importe mínimo del pedido recogida",
    add2: "Recibir",
    add3: "Recoger",
    add4: "Producción completa",
    add5: "Varias palabras clave están separadas por \", \" en inglés",
    add6: "Vacío",
    add7: "Gestión de usuarios",
    add8: "Lista de usuarios",
    add9: "Informe de usuario",
    add10: "Pedido de usuario",
    add11: "Detalles del usuario",
    add11a: "Preparando",
    user1: "Los usuarios no podrán realizar pedidos después de que se deshabilite. ¿Quieres deshabilitarlo?",
    user2: "Nombre de usuario",
    user3: "Introduzca el nombre de usuario",
    user4: "Teléfono",
    user5: "Introduzca el número de teléfono del usuario",
    user6: "Consulta",
    user7: "Exportar lista de usuarios Excel",
    user8: "Correo",
    user9: "Número de pedidos",
    user10: "Desactivar",
    user11: "Normal",
    user12: "Aún no hay de usuarios",
    user13: "Desbloquear",
    user14: "Lista de usuarios",
    user15: "¡Error de exportación!",
    user16: "Agregar reserva +",
    user17: "La cita ha expirado",
    user18: "En progreso",
    user19: "El contenido del mensaje del usuario",
    user20: "Fin (Completo)",
    user21: "Nombre",
    user22: "Buzón",
    user23: "Mensaje",
    user24: "Seleccione el número de la mesa de reserva",
    user25: "El formato del buzón es incorrecto",
    user26: "El formato del teléfono móvil es incorrecto",
    user27: "Por favor, complete la información completa",
    user28: "¿Estás seguro de completar?",
    user29: "¿Estás seguro de cancelar?",
    user30: "El mensaje del usuario",
    user31: "Aceptar reserva",
    user32: "Nuevo reservado",
    choiceTabs5: "Reserva",
    choice59: "Cancelar pedido",
    choice60: "Confirmar",
    add12: "QR para realizar un pedido",
    add13: "Número de usuarios",
    add14: "Crecimiento mensual",
    add15: "Año tras año",
    add16: "Volumen activo diario",
    add17: "Siga el número de restaurantes",
    add18: "Total de pedidos de comida",
    add19: "Ordenar este mes",
    add20: "Cantidad media diaria de pedidos",
    add21: "Total de pedidos para llevar",
    add22: "Ranking de consumo de usuarios",
    add23: "Por cantidad",
    add24: "Presione el número de veces",
    add25: "Ver más",
    add26: "Nombre de usuario",
    add27: "Cuenta de usuario",
    add28: "Número de consumo",
    add29: "Cantidad de consumo",
    add30: "Ranking de ventas de platos",
    add31: "Ventas diarias",
    add32: "Número de copias vendidas",
    add33: "Importe de ventas",
    add34: "Imagen",
    add35: "Nombre del plato",
    add36: "Ventas diarias (plato)",
    add37: "Contraer",
    add38: "Últimas noticias",
    add39: "Nuevas funciones",
    add40: "Noticias de la industria",
    add41: "Visto",
    add42: "Ver",
    add43: "Estadísticas comerciales",
    add45: "Detalles de la versión",
    add46: "Precio de autoservicio",
    add47: "Buffet",
    add48: "Precio de uso",
    add49: "Precio adulto / por persona",
    add50: "Precio de niños / por persona",
    add51: "¿Cuántos platos puede pedir cada persona por ronda? (Si no se establece, no habrá límite)",
    add52: "¿Cuántas rondas por mesa? (Si no se establece, no habrá límite)",
    add53: "Si no se establece, no habrá restricción",
    add54: "¡Configuración!",
    add55: "Euro",
    add56: "Precio de uso",
    add57: "Gestión de restaurantes",
    add58: "Llamar al camarero",
    add59: "Pago de usuario",
    add60: "Tiempo de entrega",
    add61: "Medio de entrega",
    add62: "Entrega",
    add63: "Recogida en tienda",
    add64: "Modo cajero",
    add65: "Modificar el número de cliente",
    add66: "Adulto",
    add67: "Niño",
    add68: "Por favor, intgrese al menos una persona",
    add69: "Por favor, ingrese el número de persona",
    add70: "En esta ronda sólo puede pedir",
    add71: "Plato",
    add72: "Activar",
    add73: "Sin permiso para abrir el cajero, comuníquese con el administrador",
    add74: "Sin permiso para facturación, comuníquese con el administrador",
    add75: "Por favor, no mueve las mesas en la misma zona",
    add76: "Facebook Pixel",
    add77: "Por favor, introduce Facebook Pixel",
    add78: "Activa email",
    add79: "Citar",
    add80: "Promoción del comercio",
    add81: "Factura",
    add82: "Número de factura/CIF/Teléfono",
    weekNum: "Una semana",
    monthNum: "Un mes",
    threeMonthNum: "Tres meses",
    sixMonthNum: "Seis meses",
    noOrderNum: "Nunca",
    add83: "Plantilla de emails",
    add84: "Notificaión por emails",
    add85: "Palabras de clave",
    add86: "Introduce la palabra de clave",
    add87: "Editar plantilla",
    add88: "Añadir plantilla",
    add89: "Introducir título",
    add90: "Notificación",
    add91: "Contenido",
    add92: "Título",
    add93: "Plantilla",
    add94: "Notificación enviada con éxito！",
    add95: "Por favor, elije la plantilla y completar el contenido de la notificación.",
    add96: "Guardado con éxito!",
    add97: "Tiempo de creación",
    add98: "Actualizar",
    add99: "Cantidad del usuario total",
    add100: "Estadísticas del usuario sin pedidos",
    add101: "Promoción",
    add102: "Destino de envío",
    add103: "Número total con éxito",
    add104: "Número total fallado",
    add105: "Tiempo de envío",
    add106: "Detalles de notificación",
    add107: "Nombre de empresa/Nombre personal",
    add108: "Información de factura",
    add109: "Convertir a factura",
    add110: "Notificación enviada con éxito！",
    add111: "Factura",
    add112: "Número de factura",
    add113: "Se necesita factura?",
    add114: "Información de factura",
    add115: "Información de cliente",
    add116: "Buscar/añadir usuario",
    add117: "Por favor, escribe la dirección de la factura",
    add118: "Por favor, completa la dirección de la factura",
    add119: "Gracias por su visita",
    add120: "Convertir con éxito",
    add121: "Imprimir factura",
    add122: "Importe de ingreso",
    add123: "Con IVA",
    add124: "Total",
    add125: "Importe de IVA",
    add126: "Importe sin IVA",
    add127: "Número del producto",
    add128: "Información de la dirección",
    add129: "Permitir realizar pedidos sin registro",
    add130: "Elija una hora para la entrega",
    add131: "Después de abrir, el usuario puede seleccionar la hora de entrega / hora de recogida al realizar un pedido",
    add132: "Navegación web abierta",
    add133: "La función buffet no está activada en la configuración. ¿Ahora activar la función buffet?",
    add134: "Ir a abrir",
    add135: "Precio de los platos",
    add136: "Una vez convertida en factura, es irrevocable. ¿Quieres continuar?",
    add137: "Recuerda el número de la empresa",
    add138: "Código",
    add139: "Fijación de precios de vacaciones",
    add140: "Por ruedas",
    add141: "Por número total de platos",
    add142: "Número máximo de pedidos por persona (no hay límite en el número de pedidos realizados)",
    add143: "Ajustes de vacaciones",
    add144: "Lunes",//周一
    add145: "Martes",
    add146: "Miércoles",
    add147: "Jueves",
    add148: "Viernes",
    add149: "Sábado",
    add150: "Domingo",
    monthName_1: "Enero",
    monthName_2: "Febrero",
    monthName_3: "Marzo",
    monthName_4: "Abril",
    monthName_5: "Mayo",
    monthName_6: "Junio",
    monthName_7: "Julio",
    monthName_8: "Agosto",
    monthName_9: "Septiembre",
    monthName_10: "Octubre",
    monthName_11: "Noviembre",
    monthName_12: "Diciembre",
    add151: "Confirmación de selección",
    add153: "El mes pasado",
    add154: "El próximo mes",
    add155: "Ha seleccionado",
    add156: "Vacaciones",
    add157: "Haga clic en la configuración del calendario",
    add158: "Fechas de vacaciones seleccionadas:",
    add159: "Por favor, seleccione una hora",
    add160: "Cambiar de cuenta o cerrar la sesión",
    add161: "Cerrar la sesión",
    add162: "Cambiar de cuenta",
    add163: "Por favor, seleccione un número de cuenta",
    add164: "Clasificación no restringida",
    add165: "Por favor, seleccione la categoría de platos que no se contarán (si no se establece, se contarán en el recuento de platos)",
    add166: "Centro de ayuda",
    add167: "Acuerdo de registro",
    add168: "Derechos de autor del sitio web",
    add169: "Por favor, introduzca un aviso de copyright",
    add170: "Configuración de redacción publicitaria",
    add171: "Hasta el punto",
    add172: "Platos",
    add173: "Rueda",
    add174: "Descuentos por reserva",
    add175: "Descuentos por reserva",
    add176: "Entrar para mostrar el descuento",
    add177: "Mostrar los descuentos programados cuando están activados",
    add178: "Por favor, introduzca un descuento de reserva",
    add179: "Zona horaria",
    add180: "Seleccione la zona horaria donde se encuentra el restaurante",
    add181: "Logo",
    add182: "QR del sitio web",
    add183: "Introducción QR",
    add184: "Subiendo",
    add185: "Configuración de precio",
    add186: "No establecido, el sistema tiene el precio 1 predeterminado",
    add187: "Precio predeterminado",
    add188: "Precio por persona",
    add189: "Adulto",
    add190: "Niños",
    add191: "Finalizar",
    add192: "Exportar Excel",
    add193: "Lista de calles",
    add194: "Creado",
    add195: "Calle",
    add196: "Nombre de calle",
    add197: "Confirmar",
    add198: "No se puede restaurar después de la eliminación, ¿está seguro de eliminar?",
    add199: "Establecer clasificación",
    add200: "Ordenar",
    add201: "Activar / desactivar la función de pedido normal",
    add202: "PDF",
    add203: "Escanee el QR solo para ver el pdf, no puede ordenar por sí mismo",
    add204: "Arrastra el archivo aquí, o",
    add205: "Haga clic para cargar",
    add206: "Cargue un pdf",
    add207: "Descargar QR",
    add208: "Solo cargue PDF y no más de 3 M",
    add209: "Habilitar / Código de escaneo del usuario para ordenar por usted mismo. Cerrar / Solo mire el menú y no ordene",
    add210: "Activar / desactivar pedidos",
    add211: "¡Abra al menos un buffet y un servicio de pedidos ordinario!",
    add212: "Hoy",
    add213: "Semana",
    add214: "Un mes",
    add215: "Medio año",
    add216: "Un año",
    add217: "Color del tema del sitio web",
    add218: "Color personalizado",
    add219: "Más",
    add220: "No se admite el pago por separado",
    add221: "El pedido no está abierto actualmente",
    add222: "cajón",
    add223: "Se han pagado",
    add224: "Platos seleccionados actualmente para pagar",
    add225: "El monto del pago es menor que el monto a cobrar",
    add226: "Imprimir",
    add227: "Cambiar",
    add228: "Modificar después de pedido",
    add229: "No se encontró artículo",
    add230: "Límite de código de escaneo",
    add231: "Anuncio",
    add232: "Después de abrir, se mostrará el sitio web",
    add233: "Pedido de hoy",
    add234: "Entrega",
    add235: "Contraseña",
    add236: "Mostrar",
    add237: "Ver ",
    add238: "Tienda",
    add239: "Comentarios",
    add240: "Eliminar",
    add241: "Debe agregarse",
    add242: "Orden de impresión",
    add243: "Por número",
    add244: "Por orden",
    add245: "Seleccione la impresora, la impresión configurada en la categoría ya no tendrá efecto",
    add246: "Agregar hora",
    add247: "¡Primero guarde la propiedad editada actualmente!",
    add248: "Modificar producto",
    add249: "Aún no modificado",
    add250: "Pedido Activado/Desactivado",
    add251: "bufé encendido/apagado",
    add252: "Pequeño",
    add253: "Mediana",
    add254: "Grande",
    add255: "Seleccionar tamaño de fuente",
    add256: "Ingrese su contraseña",
    add257: "Siempre pide una contraseña",
    add258: "Descargar plantilla",
    add259: "Importar Usuario",
    add260: "Consumo total",
    add261: "Fecha del último consumo",
    add262: "Reserva",
    add263: "Nueva reserva",
    add264: "Recibido",
    add265: "Rechazado",
    add266: "Cancelado",
    add267: "Descuento permitido",
    add268: "Si los usuarios pueden realizar pedidos",
    add269: "Los usuarios no pueden enviar pedidos después del cierre",
    add270: "Entrega",
    add271: "Agregar Platos",
    add272: "Solo Esta máquina",
    add273: "Configuración",
    add274: "Puede ocultar información no deseada. Cancele √ para ocultar",
    add275: "Desmarque para ocultaro",
    add276: "Sincronización descuento web y cajero",
    add277: "No se puede obtener la lista",
    add278: "Impresora no encontrada",
    add279: "不计算 不允许折扣的商品",
    add280: "预定日期",
    add281: "预定人数",
    add282: "自助不允许根据菜品支付",
    add283: "该订单不可按菜品支付",
    add284: "请根据菜品支付",
    add285: "请先选择菜品或设置应收",
    add286: "已选",
    add287: "套餐不可修改",
    add288: "超过限制数量",
    add289: "套餐数量",
    add290: "未找到打印机",
    add291: "该菜品不存在",
    add292: "未找到菜品信息",
    add293: "多份",
    add294: "套餐菜品",
    add295: "Por categoría",
    add296: "Nombre de empresa",
    add297: "备注：与菜品一起打印",
    add298: "选择为网站首页",
    add299: "网站导航",
    add300: "菜品下单",
    add301: "设置常用运费",
    add302: "请输入运费",
    add303: "运费",
    add304: "网站语言",
    add305: "西班牙语",
    add306: "意大利语",
    add307: "葡萄牙语",
    add308: "英语",
    add309: "法语",
    add310: "德语",
    add311: "中文",
    add312: "语言",
    add313: "请先启用语言",
    add314: "前往",
    add315: "多语言",
    add316: "Otra impresión",
    add317: "Dir./Mesa",
}
