import React from 'react';
import './index.scss';
import Tr from "@util/translate";

export default class InputNumber extends React.Component{
    constructor(props) {
        super(props);
    }
    inputNumber = (val)=>{
        let { text='', textChange } = this.props;
        textChange(text + '' + val);

    }
    cancelInput = () => {
        const { text='', textChange } = this.props;
        if (text.length > 0)
            textChange(text.slice(0, -1));
    }
    render(){
        const { sureAction } = this.props;

        return (
            <div className="input-number-container">
                {["7", "8", "9", "4", "5", "6", "1", "2", "3", "0"].map(
                    val => {
                        return (
                            <li
                                onClick={() => {
                                    this.inputNumber(val);
                                }}
                            >
                                {val}
                            </li>
                        );
                    }
                )}
                <li onClick={ this.cancelInput }>
                    <img src="/public/img/del.svg" alt=""/>
                </li>
                <li className="sureBtn" onClick={ sureAction }>{ Tr('add197') }</li>
            </div>
        )
    }
}