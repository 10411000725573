import React, {Component} from "react";
import initEditor from './initEditor';
import {Button, message, Upload, Tabs, Input} from 'antd';
import Req from "@util/fetch";
import {debounce} from "@util/util";
import Tr from "@util/translate";
import HelpCenter from './../HelpCenter';
import RegisterAgreement from './../RegisterAgreement';

const {TabPane} = Tabs;

export default class AboutUs extends Component {
    constructor() {
        super();
        this.state = {
            key: "about",
        };
    }

    componentDidMount() {
        this.getAboutUs();
        this.getCopyRight();
    }

    getAboutUs = () => {
        Req(
            {
                url: `/v1/store?guid=undefined`,
                type: "GET",
                loadingTips: Tr("searching"),
                hideLoading: true,
                errorTips: Tr("discountList5")
            },
            res => {
                if (res) {
                    let data = res.data;
                    if (data.aboutCoverPath && data.about) {
                        let a = data.aboutCoverPath.split('/');
                        this.setState({
                            fileList: [{
                                uid: '1',
                                name: a[a.length - 1],
                                status: 'done',
                                url: data.aboutCoverPath,
                                thumbUrl: data.aboutCoverPath,
                            }]
                        })
                        initEditor({
                            container: this.container,
                            setEditor: (editor) => {
                                this.editor = editor
                            },
                            html: data.about
                        });
                    } else {
                        initEditor({
                            container: this.container,
                            setEditor: (editor) => {
                                this.editor = editor
                            }
                        });
                    }
                }
            }
        );
    }
    getCopyRight = () => {
        Req(
            {
                url: `/v1/webCopyright`,
                type: "GET",
                loadingTips: Tr("searching")
            },
            res => {
                if (res) {
                    this.setState({
                        copyrightData: res.data || ''
                    })
                }
            }
        );
    }
    save = () => {
        const {fileList} = this.state;
        const about = this.editor.txt.html();
        let file = fileList && fileList[0],
            aboutCoverPath;
        // if(!file){
        //     message.error(Tr("aboutus1"));
        //     return ;
        // }
        if (file && file.response) {
            if (file.response.isError) {
                // message.error(Tr("aboutus1"));
                // return ;
            } else {
                aboutCoverPath = file.response.data.url;
            }

        } else if (file && !file.response) {
            aboutCoverPath = file.url;
        }
        Req({
            url: "/v1/store/addAbout",
            type: "POST",
            data: {
                aboutCoverPath,
                about
            }
        }, res => {
            if (res) {
                message.success(Tr("saveSuccess"))
            }
        })
    }

    saveCopyRight = () => {
        const {copyrightData} = this.state;
        Req({
            url: "/v1/webCopyright",
            type: "POST",
            data: copyrightData
        }, res => {
            if (res) {
                message.success(Tr("saveSuccess"))
            }
        })
    }


    tabChange = (key) => {
        this.setState({
            key
        })
    }

    saveCopyRightDeb = debounce(this.saveCopyRight, 500)

    render() {
        const {fileList, key, copyrightData} = this.state;
        return (
            <div className="page-container">
                <h3>
                    <i className="icon iconfont icon-edit5"
                       style={{verticalAlign: "-2px", fontSize: "22px"}}> </i>
                    {Tr("add170")}
                </h3>
                <div style={{padding: 15}}>
                    <Tabs value={key} onChange={key => this.tabChange(key)}>
                        <TabPane tab={Tr("discountList31")} key="about">
                            <Upload
                                action={window.serverUrl + "/v1/upload"}
                                fileList={fileList}
                                listType='picture'
                                onChange={({file, fileList}) => {
                                    this.setState({
                                        fileList: fileList.length ? [file] : []
                                    })
                                }}
                            >
                                <Button>{Tr("register27")}</Button>
                            </Upload>
                            <div>
                                <div style={{margin: '35px auto'}} ref={container => {
                                    this.container = container
                                }}></div>
                            </div>
                            <div>
                                <Button type="primary" onClick={this.save}>{Tr("save")}</Button>
                            </div>
                        </TabPane>
                        <TabPane tab={Tr('add166')} key="">
                            <HelpCenter/>
                        </TabPane>
                        <TabPane tab={Tr("add167")} key="register">
                            <RegisterAgreement/>
                        </TabPane>
                        <TabPane tab={Tr("add168")} key="copyright">
                            <Input value={copyrightData && copyrightData.content}
                                   style={{ margin: '15px 0', width: '50%'}}
                                   onChange={(e) => this.setState({copyrightData: { ...(copyrightData || {}), content: e.target.value }}, this.saveCopyRightDeb)}
                                   placeholder={Tr("add169")}/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}
