import React, { Component } from 'react';
import './index.scss';
import Tr from "@util/translate";
import {Button, Icon, Input, Pagination, Select} from "antd";
import { Link } from "react-router-dom";
import Req from "@util/fetch";

const Option = Select.Option;

export default class CustomerRank extends Component{
    constructor(props){
        super(props)
    }
    state={
        queryParams: {
            page: 1,
            pageSize: 100,
            type: 1
        }
    }

    componentDidMount(){
        this.getCustomerRank();
    }

    queryParamsChange = (value, key)=>{
        const { queryParams } = this.state;
        this.setState({
            queryParams: {
                ...queryParams,
                [key]: value.target ? value.target.value : value
            }
        }, ()=>{
            if(key === 'page') this.getCustomerRank();
        })
    }

    getCustomerRank = ()=>{
        const { queryParams } = this.state;
        let params = `?`;
        this.setState({ loading: true })
        for (let key in queryParams) {
            params += `&${key}=${queryParams[key]}`;
        }
        Req({
            url: "/v1/customer/customerRank" + params,
            type: "GET"
        }, res=>{
            this.setState({ loading: false })
            if(res){
                this.setState({
                    list: res.data && res.data.list,
                    total: res.data && res.data.totalCount
                })
            }
        })
    }

    render(){
        const { queryParams, list=[], loading, total } = this.state;
        return (
            <div className="page-container">
                <h3>
                    <Icon type="bar-chart" />
                    {Tr("add22")}
                </h3>

                <div className="query">
                    { Tr("goodsEdit26") }
                    <Select value={ queryParams.type } onChange={ value=>this.queryParamsChange(value, 'type') }>
                        <Option value={ 1 }>{ Tr("add23") }</Option>
                        <Option value={ 2 }>{ Tr("add24") }</Option>
                    </Select>

                    <Button type="primary" onClick={ ()=>this.queryParamsChange(1, 'page') }>
                        {Tr("user6")}
                    </Button>
                </div>

                <div className="table-list">
                    <table>
                        <thead>
                        <tr>
                            <th>{Tr("no")}</th>
                            <th>{Tr("add26")}</th>
                            <th>{Tr("add28")}</th>
                            <th>{Tr("add29")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            list.map((item, index)=>{
                                return (
                                    <tr>
                                        <td>{ index + 1 }</td>
                                        <td>{ item.name }</td>
                                        <td>{ item.order_count }</td>
                                        <td>{ item.price_sum }</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>

                    {
                        (!list || !list.length ) && !loading ? (
                            <div className="noData">{Tr("bill15")}</div>
                        ) : null
                    }

                    <Pagination
                        onChange={ value=>{ this.queryParamsChange(value, 'page') }  }
                        pageSize={ queryParams.pageSize }
                        current={ queryParams.page }
                        total={ total || 0 }
                    />
                </div>
            </div>
        )
    }
}
