//组建用于集中导出自定义的所有组件 方便在别的组件中批量、多次调用

import Loadable from "./common/Loadable";
export Loading from "./common/Loading";

export Header from "./common/Header";
export LeftMenu from "./common/LeftMenu";

export const Login = Loadable({
  loader: () => import("./Login")
});
export const Register = Loadable({
  loader: () => import("./Register")
});
export const Content = Loadable({
  loader: () => import("./Content")
});
export const Index = Loadable({
  loader: () => import("./Index")
});
export const OpenOrder = Loadable({
  loader: () => import("./OpenOrder")
});
export const TodayList = Loadable({
  loader: () => import("./TodayList")
});
export const HistoryList = Loadable({
  loader: () => import("./HistoryList")
});

// 后台界面路由
export const Statistics = Loadable({
  loader: () => import("./Admin/Statistics")
});
export CustomerRank from "./Admin/Statistics/CustomerRank";
export DishRank from "./Admin/Statistics/DishRank";
export PublishDetail from "./Admin/Statistics/PublishDetail";

export const GoodsList = Loadable({
  loader: () => import("./Admin/Goods/GoodsList")
});
export const SaleData = Loadable({
  loader: () => import("./Admin/Goods/SaleData")
});
export const TodayOrder = Loadable({
  loader: () => import("./Admin/TodayOrder")
});
export const GoodsEdit = Loadable({
  loader: () => import("./Admin/Goods/GoodsEdit")
});
export const GoodsKindsList = Loadable({
  loader: () => import("./Admin/GoodsKinds/GoodsKindsList")
});
export const GoodsKindsEdit = Loadable({
  loader: () => import("./Admin/GoodsKinds/GoodsKindsEdit")
});
export const StaffList = Loadable({
  loader: () => import("./Admin/Staff/StaffList")
});
export const StaffEdit = Loadable({
  loader: () => import("./Admin/Staff/StaffEdit")
});
export const UpdatePwd = Loadable({
  loader: () => import("./Admin/Staff/UpdatePwd")
});
export const PostList = Loadable({
  loader: () => import("./Admin/Post/PostList")
});
export const PostEdit = Loadable({
  loader: () => import("./Admin/Post/PostEdit")
});
export const PropList = Loadable({
  loader: () => import("./Admin/Goods/PropList")
});
export const PropEdit = Loadable({
  loader: () => import("./Admin/Goods/PropEdit")
});
export const RoleList = Loadable({
  loader: () => import("./Admin/Role/RoleList")
});
export const RoleEdit = Loadable({
  loader: () => import("./Admin/Role/RoleEdit")
});
export const OrderList = Loadable({
  loader: () => import("./Admin/Order/OrderList")
});
export const OrderDetail = Loadable({
  loader: () => import("./Admin/Order/OrderDetail")
});

export const StoreEdit = Loadable({
  loader: () => import("./Admin/Store/StoreEdit")
});
export const StoreList = Loadable({
  loader: () => import("./Admin/Store/StoreList")
});

export AboutUs from "./Admin/Store/AboutUs";
export PaySetting from "./Admin/Store/PaySetting";

export const Printer = Loadable({
  loader: () => import("./Admin/Printer")
});
export const TableManage = Loadable({
  loader: () => import("./Admin/TableManage")
});

export const DiscountList = Loadable({
  loader: () => import("./Admin/Discount/DiscountList")
});
export const DiscountEdit = Loadable({
  loader: () => import("./Admin/Discount/DiscountEdit")
});
export const BannerEdit = Loadable({
  loader: () => import("./Admin/Discount/BannerEdit")
});
export const BusinessHours = Loadable({
  loader: () => import("./Admin/BusinessHours")
})
export const PrintQrcode = Loadable({
  loader: () => import("./Admin/TableManage/Print")
})
export const TurnOver = Loadable({
  loader: () => import("./Admin/TurnOver")
})
export const TurnOverOrder = Loadable({
  loader: () => import("./Admin/TurnOver/OrderList")
})
export const UserList = Loadable({
  loader: () => import("./Admin/User/UserList")
})
export const UserOrder = Loadable({
  loader: () => import("./Admin/User/UserOrder")
})
export const UserDetail = Loadable({
  loader: () => import("./Admin/User/UserDetail")
})
export const StoreSetting = Loadable({
  loader: () => import("./Admin/Store/StoreSetting")
})
export const StoreAddBank = Loadable({
  loader: () => import("./Admin/Store/AddBank")
})
export const InvoiceList = Loadable({
  loader: () => import("./Admin/Invoice/List")
})

export const MailTemplate = Loadable({
  loader: () => import("./Admin/User/MailTemplate")
})
export const MailTemplateEdit = Loadable({
  loader: () => import("./Admin/User/MailTemplateEdit")
})
export const MailPush = Loadable({
  loader: () => import("./Admin/User/MailPush")
})
export const MailPushDetail = Loadable({
  loader: () => import("./Admin/User/MailPushDetail")
})
