import { createStore, combineReducers } from "redux";
import { global, admin } from "./reducer/global";

export default function initRedux() {
  const reducer = combineReducers({
    global,
    admin
  });
  return createStore(reducer);
}
