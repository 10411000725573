import React, {Component, Fragment} from "react";
import {connect, Provider} from "react-redux";
import {HashRouter, Route} from "react-router-dom";
import {Content, Login, Register, PdfRead} from "./component";
import Admin from "./Admin";
import {actions} from "./../redux/action/common";
import Req from "./../util/fetch";
import "./../style/style.scss";
import PrintQrcode from './Admin/TableManage/Print';
import PrintStoreQrcode from './Admin/Store/StorePrinter';
import {Modal} from "antd";
import { v4 as generateUid } from 'uuid';
import ConfrimPassword from "./common/ConfrimPassword";
import Tr from "@util/translate";
import io from "socket.io-client";

let socket;

@connect(state => {
        return {
            userInfo: state.global.userInfo || null,
            lang: state.global.lang
        };
    },
    dispatch => {
        return {
            langActions: data => {
                dispatch(actions.lang(data));
            }
        };
    })
export default class Root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: null
        }
        this.socket = null
    }

    componentDidMount() {
        window.webClientId = generateUid(); //生成客户端id 用于打印消息区分客户端
        window.hasPermisionCode = this.hasPermisionCode;
        window.confrimPasswordAction = this.confrimPassword;
        window.refreshUserInfo = this.getUserInfo;
        window.stopRefreshDataFunc = (flag)=>{
            window.stopRefreshData = flag;
            setTimeout(()=>{
                window.stopRefreshData = false;
            }, 5000);
        }
        window.clearGoodsCache = ()=>{
            sessionStorage.removeItem("goodsListData");
            sessionStorage.removeItem("goodsListDataBuffet");
            sessionStorage.removeItem("commodityCategoryData");
        }
        window.loadGoogleScriptTimer = setInterval(()=>{
            try {
                if(google){
                    console.log(new google.maps.Geocoder());
                    window.loadGoogleScriptTimer && clearInterval(window.loadGoogleScriptTimer)
                }
            }catch (e){
                console.log(e);
            }
        }, 100)
        window.getStoreInfo = this.getStoreInfo;
        window.getOrderDetail = this.getOrderDetail;
        window.setAccountInfo = this.setAccountInfo;

        if (localStorage.getItem("userInfo")) {
            this.getUserInfo();
        } else {
            if(["#/", "#/login", "#/register", "#/pdf"].includes(location.hash.split("?")[0])) return ;
            location.hash = "/";
        }

        //若线上不是https则改为https
        if(!(location.hostname === 'localhost') && location.protocol === 'http:'){
            location.protocol = 'https:'
        }
    }

    componentWillReceiveProps(next) {
        if (next.lang !== window.selectedLanguage) {
            window.selectedLanguage = next.lang;
        }
    }

    // 链接websocket
    connectWebsocket = () => {
        const options = {
            reconnection: true,
            autoConnect: false,
        }
        const storeData = JSON.parse(localStorage.getItem("storeData"));
        if(!storeData) return ;
        if(location.href.includes('dev') || location.href.includes('localhost')){
            this.socket = io(window.protocolHTTPS + '//dev-recycle-bin.didigo.es:13010', options)
        }else{
            this.socket = io(window.protocolHTTPS + '//recycle-bin.didigo.es:13010', options)
        }
        this.socket.connect();
        this.socket.on('disconnect', () => {
            console.log("socket 断开链接");
            this.timer && clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.socket.connect();
            }, 10000);
        });
        this.socket.on('connect', () => {
            console.log('socket 连接成功');
            this.timer && clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.socket.emit('msg', 'ping');
            }, 10000);
        });
        this.socket.on('reconnect', () => {
            console.log("重新连接到服务器");
            this.timer && clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.socket.emit('msg', 'ping');
            }, 10000);
        });

        //监听数据有更新 type 1商品 2订单 3预约订单 4桌位 5 区域
        this.socket.on(`message_refresh_${storeData.currentStore.guid}`, data => {
            const { showTabs } = this.state;
            data = JSON.parse(data);
            console.log('监听到更新2：', data);
            switch (data.type) {
                case 1:
                    window.clearGoodsCache && window.clearGoodsCache();
                    setTimeout(()=>{
                        window.getAllGoodsApiRoot && window.getAllGoodsApiRoot();
                    })
                    break;
                case 2:
                    window.refreshTablesData && window.refreshTablesData();
                    window.getOrderCountNums && window.getOrderCountNums();
                    window.refreshTakeawayOrder && window.refreshTakeawayOrder();
                    if(showTabs === 'takeaway')this.getTakeawayQueryCondition();
                    break;
                case 3:
                    window.refreshBookData && window.refreshBookData();
                    window.getOrderCountNums && window.getOrderCountNums();
                    break;
                case 4:
                    window.refreshTablesData && window.refreshTablesData();
                    break;
                case 5:
                    window.getAreaData && window.getAreaData();
                    break;
            }

        })
        //监听呼叫服务员
        this.socket.on(`message_callWaiter_${storeData.currentStore.guid}`, data => {
            window.refreshTablesData && window.refreshTablesData();
            console.log('监听到呼叫服务员消息', data);
            data = JSON.parse(data);
            this.showNotificationForWaiterOrOrder(Tr("add58"), data && data.data && data.data.content, data);
        })
        //用户用户要结算订单
        this.socket.on(`message_settleorder_${storeData.currentStore.guid}`, data => {
            window.refreshTablesData && window.refreshTablesData();
            console.log('监听到结算订单消息', data);
            data = JSON.parse(data);
            this.showNotificationForWaiterOrOrder(Tr("add59"), data && data.data && data.data.content, data);
        })
        //监听订单结账刷新桌位单
        this.socket.on(`message_for_store_'${storeData.currentStore.guid}`, data => {
            window.refreshTablesData && window.refreshTablesData();
        })
        //监听新订单
        this.socket.on(`message_store_${storeData.currentStore.guid}`, (data) => {
            if (data) {
                data = JSON.parse(data);
                // 扫码点餐订单推送队列
                let noticeList = localStorage.getItem("noticeList")
                    ? JSON.parse(localStorage.getItem("noticeList")) : []
                //外卖
                noticeList.push(data);
                localStorage.setItem("noticeList", JSON.stringify(noticeList));

                if (this.state.isShowNotice == false) {
                    this.showNotification()
                }

                this.checkIsHaveNotice();

                this.setState({
                    childUpdate: this.state.childUpdate
                        ? ++this.state.childUpdate
                        : 1
                });
                this.getOrderCount()
            }
        });
        this.socket.on(`message_orderupdate_${storeData.currentStore.guid}`, (data) => {
            if (data) {
                data = JSON.parse(data);
                console.log('加菜', data);
                // 扫码点餐订单推送队列
                let noticeList = localStorage.getItem("noticeList")
                    ? JSON.parse(localStorage.getItem("noticeList")) : []
                //外卖
                noticeList.push(data);
                localStorage.setItem("noticeList", JSON.stringify(noticeList));

                if (this.state.isShowNotice == false) {
                    this.showNotification()
                }

                this.checkIsHaveNotice();

                this.setState({
                    childUpdate: this.state.childUpdate
                        ? ++this.state.childUpdate
                        : 1
                });
                this.getOrderCount()
            }
        });

        this.socket.on(`message_print_daily_for_${window.webClientId}`, (data) => {
            console.log('接收到客户端打印消息', data);
            printFunc(data);
        })
        this.socket.on(`message_print_monthly_for_${window.webClientId}`, (data) => {
            console.log('接收到客户端打印消息', data);
            printFunc(data);
        })
        this.socket.on(`message_print_daily_for_${storeData.currentStore.guid}`, (data) => {
            console.log('接收到日结账打印消息', data);
            printFunc(data);
        })
        this.socket.on(`message_print_monthly_for_${storeData.currentStore.guid}`, (data) => {
            console.log('接收到日营业额 月营业额打印消息', data);
            printFunc(data);
        })
        this.socket.on(
            `message_print_reserve_for_${storeData.currentStore.guid}`,
            data => {
                console.log("接收到预备金额打印消息", data);
                printFunc(data);
            }
        );

        try{
            if (window.clientName !== 'android') {
                //当前店铺的消息
                this.socket.on(`message_store_print_${storeData.currentStore.guid}`, (data) => {
                    console.log('接收到打印消息', data);
                    printFunc(data, true);
                })
                //区分客户端的打印消息
                this.socket.on(`message_store_print_${window.webClientId}`, (data) => {
                    console.log('接收到客户端打印消息', data);
                    printFunc(data, true);
                })
                //发票打印
                this.socket.on(`message_print_invoice_for_${window.webClientId}`, (data) => {
                    console.log('接收到发票打印消息', data);
                    printFunc(data);
                })
                this.socket.on(`message_print_invoice_for_${storeData.currentStore.guid}`, (data) => {
                    console.log('接收到发票打印消息', data);
                    printFunc(data);
                })
            } else if (window.clientName === 'android') {
                let origin = location.origin;
                this.socket.on(`message_store_android_print_${storeData.currentStore.guid}`, (data) => {
                    console.log('接收到打印消息', data);
                    data = JSON.parse(data);
                    try {
                        if (data.drawer) {
                            alert("触发安卓开钱箱功能-socket-监听");
                            Android.openCash()
                        }
                        switch (data.type) {
                            case 'locale': // 发生了本地打印事件，需要前端自行调用机接口进行打印.
                                setTimeout(() => {
                                    let count = data.cnt || 1;
                                    for (let i = 0; i < count; i++) {
                                        Android.printData(origin + data.content, data.model, data.lang, data.target);
                                    }
                                    this.setPrinted(data.orderGuid);
                                }, 2000);

                                break;
                            case 'web':
                                break;
                            default:
                                ;
                        }
                    } catch (err) {
                        console.log(err);
                    }
                })
            }
        }catch (e){
            console.log(JSON.stringify(e));
        }
        let _this = this;
        function printFunc(data, setPrinted){
            data = JSON.parse(data);
            console.log('格式化打印消息', data);
            window.testPrinterExisted(data.name, ()=>{
                switch (data.type) {
                    case 'locale': // 发生了本地打印事件，需要前端自行调用打印机接口进行打印.
                        // 消息格式如下:
                        // message.type // 打印机类型
                        // message.name // 打印机名字
                        // message.content // 打印机打印内容
                        // message.cnt // 打印机打印份数
                        if (window.jsObj || window.printData) {
                            let count = data.cnt;
                            for (let i = 0; i < count; i++) {
                                try {
                                    let uniqueValue = data.orderGuid + '_' + data.sceneType + '_' + i + '_' + data.guid//如果不是唯一的值可能会丢单
                                    if (window.printData) {
                                        console.log('window.printData 方法存在');
                                        window.printData({
                                            str: data.content,
                                            deviceName: data.name,
                                            unique: uniqueValue,
                                        }, (res) => {
                                            if (setPrinted && data.orderGuid && res === 'success-' + uniqueValue) {
                                                _this.setPrinted(data.orderGuid)
                                            }
                                        })
                                    } else {
                                        console.log('window.jsObj 方法存在');
                                        window.jsObj.PrintDocument && window.jsObj.PrintDocument(
                                            data.content,
                                            data.name,
                                            uniqueValue,
                                            (res) => {
                                                if (setPrinted && data.orderGuid && res === 'success-' + uniqueValue) {
                                                    _this.setPrinted(data.orderGuid)
                                                }
                                            }, 1)
                                    }
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        } else {
                            console.log('打印方法不存在');
                        }
                        break;
                    case 'web':
                        break;
                    // 发生了云打印事件，其中消息格式与本地打印机相同
                    // 不同的是，前端不再需要调用打印逻辑，只需要通知用户发生了一起打印事件即可
                    default: ;
                }
            })
        }
    }
    //设置订单为已打印
    setPrinted = (guid) => {
        Req({
            url: `/v1/order/${guid}/printed`,
            type: "POST",
        }, res => {
            if (res) {
                console.log(`设置${guid}为已打印成功`);
            }
        })
    }
    hasPermisionCode = (code)=>{
        const { userInfo } = this.props;
        let permissions = userInfo && userInfo.permissions || [];
        if(!permissions || !permissions.includes(code)){
            return false;
        }
        return true;
    }
    confrimPassword = (code, callback)=>{
        const { userInfo } = this.props;
        let role_permission_pwd = userInfo && userInfo.role_permission_pwd || [];
        if(window.showConfrimPassword) return ;
        if(role_permission_pwd.includes(code)){
            window.showConfrimPassword = true;
            Modal.confirm({
                className: 'confirmUsersPasswordModal',
                content: <ConfrimPassword callback={ callback } />,
                icon: null,
                maskClosable: true,
                onCancel() {
                    window.showConfrimPassword = false;
                }
            })
        }else{
            callback && callback()
        }
    }
    getUserInfo = (callback) => {
        if (["#/", "#/login", "#/register", "#/pdf"].includes(location.hash)) {
            return false;
        }
        Req(
            {
                url: "/v1/account",
                type: "GET"
            },
            res => {
                if(res){
                    if(callback && typeof callback === 'function'){
                        callback(res.data);
                        return ;
                    }
                    this.setAccountInfo(res.data || {});
                }
            },
            error => {
                this.props.store.dispatch(actions.userInfo(null));
            }
        );
    };
    setAccountInfo = (accountInfo)=>{
        localStorage.setItem("storeData", JSON.stringify(accountInfo));
        setTimeout(()=>{
            this.connectWebsocket();
        });
        this.props.store.dispatch(
            actions.userInfo({
                ...(JSON.parse(localStorage.getItem("userInfo")) || {}),
                headImage: accountInfo.headImage,
                permissions: accountInfo.permissions,
                role_permission_pwd: accountInfo.role_permission_pwd
            }),
        );
        let sessionLang = localStorage.getItem('lang');
        if (!sessionLang) {
            window.selectedLanguage = accountInfo.lang;
            this.props.langActions(accountInfo.lang);
        } else {
            window.selectedLanguage = sessionLang;
            this.props.langActions(sessionLang);
        }
    }
    getOrderDetail = (id, callBack) => {
        Req(
            {
                url: `/v1/order/dine?guid=${id}`,
                type: "GET",
                loadingTips: Tr("searching"),
                errorTips: Tr("choice7")
            },
            res => {
                if (res) {
                    let data = res.data || {};
                    callBack && callBack(data);
                }
            }
        );
    }

    render() {
        const {userInfo} = this.props || {};
        return (
            <Provider store={this.props.store}>
                <HashRouter>
                    <Fragment>
                        <Route path="/" exact component={Login}/>
                        <Route path="/login" exact component={Login}/>
                        <Route path="/pdf" exact component={PdfRead}/>
                        <Route path="/register" exact component={Register}/>
                        {
                            (userInfo || null) && this.props.lang && <Fragment>
                                <Route path="/content" component={Content}/>
                                <Route path="/admin" component={Admin}/>
                                <Route path="/print" component={PrintQrcode}/>
                                <Route path="/store/print/:guid" component={PrintStoreQrcode}/>
                            </Fragment>
                        }
                    </Fragment>
                </HashRouter>
            </Provider>
        );
    }
}

window.testPrinterExisted = (name, callBack)=>{
    try{
        if(window.getPrinterList){
            window.getPrinterList && window.getPrinterList(list=>{
                console.log('找到的打印机列表', list, name);
                if(list.find(item=>item.name === name)){
                    callBack && callBack();
                }else{
                    console.log('未找到对应的打印机');
                    // alert(Tr('add290'));
                }
            })
        }else{
            try{
                let str = window.jsObj.GetPrintList();
                if(str){
                    let list = JSON.parse(str);
                    console.log('找到的打印机列表', list, name);
                    if(list.find(item=>item.name === name)){
                        callBack && callBack();
                    }else{
                        console.log('未找到对应的打印机');
                        // alert(Tr('add290'));
                    }
                }
            }catch (e) {
                console.log(e);
            }
        }
    }catch(e){
        console.log(e);
    }
}