import React from 'react';
import classnames from "classnames";
import Tr from "@util/translate";
import Req from "@/util/fetch";

export default class OpenCash extends React.Component{
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        window.globalOpenCash = this.openCash;
    }

    openCash = () => {
        if(!window.hasPermisionCode("OPEN_WALLET")){
            alert(Tr("add73"));
            return ;
        }

        if(window.clientName !== 'android'){
            if(!window.jsObj || !window.jsObj.OpenCashBox){
                alert("未找到开钱箱方法");
                return ;
            }
            try{
                window.jsObj.OpenCashBox();
            }catch (e) {
                console.log(e);
            }
            return ;
        }

        // 安卓
        try {
            Android.openCash();
        } catch (e) {
            console.log("打开钱箱出现错误", e);
        }
    };
    render(){
        return (
            <div
                className={classnames({
                    menu: true,
                    menuClickActive: true
                })}
                title={'F4'}
                onClick={ ()=>{
                    this.openCash()
                } }
            >
                <img
                    className="active"
                    src="./public/img/aside/money_w.png"
                    alt=""
                />
                <img src="./public/img/aside/money.png" alt=""/>
                <span>{Tr("add222")}(F4)</span>
            </div>
        )
    }
}