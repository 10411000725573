import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Input,
  Select,
  Button,
  message,
  Pagination,
  Checkbox,
  DatePicker
} from "antd";
import Req from "../../../../util/fetch";
import { actions } from "@/redux/action/common";
import Tr from "@util/translate";
import "./index.scss";

const Option = Select.Option;

@connect(
  state => {
    return {
      storeList: state.admin.storeList || []
    };
  },
  dispatch => {
    return {};
  }
)
export default class StoreList extends Component {
  constructor() {
    super();
    this.state = {
      selected: [], //选中的商品
      data: [],
      queryParams: {
        pageIndex: 1,
        pageSize: 20,
        storeGuid: "",
        categoryGuid: ""
      }
    };
  }
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    const { queryParams } = this.state;
    let params = `?`;
    for (let key in queryParams) {
      params += `&${key}=${queryParams[key]}`;
    }
    Req(
      {
        url: `/v1/store/search${params}`,
        type: "GET",
        loadingTips: Tr("searching")
      },
      res => {
        if (res) {
          this.setState({
            data: (res.data && res.data.collection) || [],
            total: res.data.totalCount,
            selected: []
          });
        }
      }
    );
  };
  queryChange = (e, key) => {
    e = e.target ? e.target.value : e;
    let { queryParams } = this.state;
    queryParams[key] = e;
    this.setState({
      queryParams
    });
  };
  pageChange = (index, size) => {
    const { queryParams = {} } = this.state;
    const { pageSize } = queryParams;
    this.setState(
      {
        queryParams: Object.assign({}, queryParams, {
          pageIndex: size !== pageSize ? 1 : index,
          pageSize: size
        })
      },
      () => {
        this.getData();
      }
    );
  };
  deleteAction = id => {
    let ids;
    if (!id) {
      ids = this.state.selected.join();
    } else {
      ids = id;
    }
    Req(
      {
        url: `/v1/position?guid=${ids}`,
        type: "DELETE"
      },
      res => {
        if (res) {
          message.success(Tr("discountList1"));
          this.getData();
        }
      }
    );
  };
  render() {
    const { data, queryParams = {}, total, selected = [] } = this.state || {};
    const { pageIndex, pageSize } = queryParams;
    const { storeList } = this.props;

    return (
      <div className="goods-kind-list page-container">
        <h3>
          <i className="icon iconfont icon-mendian"> </i>{ Tr("storeList1") }
        </h3>
        <div className="query">
          { Tr("printer53") }
          <Input
            value={queryParams.name}
            onChange={e => {
              this.queryChange(e, "name");
            }}
            placeholder={ Tr("storeList2") }
          />
          &nbsp;&nbsp;&nbsp;&nbsp; { Tr("orderList9") }
          <Input
            value={queryParams.phone}
            onChange={e => {
              this.queryChange(e, "phone");
            }}
            placeholder={ Tr("storeList3") }
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span>{ Tr("storeList4") }:</span>
          <DatePicker
            onChange={(date, string) => {
              this.queryChange(string, "startTime");
            }}
            placeholder=" "
            showToday={false}
            locale="zh-cn"
          />
          <span>&nbsp;&nbsp;{Tr("orderList3")}</span>
          <DatePicker
            onChange={(date, string) => {
              this.queryChange(string, "endTime");
            }}
            placeholder=" "
            showToday={false}
            locale="zh-cn"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button onClick={this.getData} type="primary">
            { Tr("search") }
          </Button>
        </div>

        <div className="table-list">
          <table>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={selected.length === data.length}
                    onChange={e => {
                      this.checkboxChange(e);
                    }}
                  >
                    { Tr("checkAll") }
                  </Checkbox>
                </th>
                <th>{ Tr("no") }</th>
                <th>{ Tr("printer53") }</th>
                <th>{ Tr("orderList9") }</th>
                <th>{ Tr("storeList5") }</th>
                <th>{ Tr("orderList16") }</th>
                <th>{ Tr("storeList4") }</th>
                <th>{ Tr("choice52") }</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((val, index) => {
                  return (
                    <tr key={val.guid}>
                      <td>
                        <Checkbox
                          checked={selected.includes(val.guid)}
                          onChange={e => {
                            this.checkboxChange(e, val.guid);
                          }}
                        >
                          {" "}
                        </Checkbox>
                      </td>
                      <td>{index + 1}</td>
                      <td>{val.name}</td>
                      <td>{val.no}</td>
                      <td>{val.takeawayPrice}</td>
                      <td>{val.price}</td>
                      <td>{val.unit && val.unit.name}</td>
                      <td className="operate">
                        <Link to={`/admin/store/edit/${val.guid}`}>{ Tr("edit2") }</Link>
                        {/*<a onClick={ ()=>{ this.deleteAction(val.guid) } } href="javascript:;">删除</a>*/}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {!data || !data.length ? (
            <div className="noData">{ Tr("storeList6") }</div>
          ) : null}

          {total ? (
            <Fragment>
              <Pagination
                onChange={this.pageChange}
                pageSize={pageSize}
                current={pageIndex}
                total={total}
              />
              <Select
                style={{ float: "right", margin: "25px 15px" }}
                value={pageSize}
                onChange={e => {
                  this.pageChange(pageIndex, e);
                }}
              >
                <Option value={20}>20</Option>
                <Option value={40}>40</Option>
                <Option value={60}>60</Option>
                <Option value={80}>80</Option>
                <Option value={100}>100</Option>
              </Select>
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}
