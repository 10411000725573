import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {Icon} from "antd";
import Tr from "@util/translate";
import Req from "@util/fetch";

class PublishDetail extends Component{
    constructor(props){
        super(props)
    }
    state = {}
    componentDidMount(){
    }
    getData = (id)=>{
        Req({
            url: `/v1/customer/read-issue?guid=${id}`,
            type: "GET"
        }, res => {
            if (res) {
            }
        }, error => {
        })
    }
    render(){
        return (
            <div className="page-container">
                <h3>
                    <Icon type="aliwangwang" />
                    {Tr("add45")}
                </h3>
            </div>
        )
    }
}

export default withRouter(PublishDetail);
