import React, {Component, Fragment} from 'react';
import Req from "@util/fetch";
import Tr from "@util/translate";
import {Button, Checkbox, DatePicker, message, Input, Row, Col, Icon, Modal, Tabs, Card} from 'antd';
import './index.scss';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CardSetupForm from './CardSetupForm';
import {getUrlParams} from "@util/util";
import moment from 'moment'

const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
const {MonthPicker} = DatePicker;
const { TabPane } = Tabs;

const MODULESLIST = [
    {name: Tr("payCardSetting20"), value: 'cash_register'},
    {name: Tr("payCardSetting23"), value: 'store_app'},
    {name: Tr("payCardSetting21"), value: 'take_away_site'},
    {name: Tr("payCardSetting22"), value: 'user_app'},
    {name: Tr("payCardSetting24"), value: 'qr_code'},
    {name: Tr("payCardSetting46"), value: 'stroe_ipad'},
];
const PERIODSMAP = {
    '1': Tr('payCardSetting15'),
    '3': Tr('payCardSetting16'),
    '6': Tr('payCardSetting17'),
    '12': Tr('payCardSetting18'),
    '24': Tr('payCardSetting19')
};
export default class Name extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        data: null,
        bankData: {},
        protocol_status: false,
        iframeSrc: "",

        publicKey: '',
        clientSecret: '',

        storeFinancial: {},
        isRead: false,
        showProtocol: false,

        protocolList: [],

        activeKey: '1',

        recordList: [],
        cardholderName: '', // 持卡人姓名
    }

    componentDidMount() {
        this.getSetting();
        this.loadPaymentHistory()
        window.closeIframe = ()=>{
            this.setState({
                iframeSrc: ""
            }, this.getSetting)
        }

        this.setState({
            activeKey: getUrlParams('tab') || '1'
        })
    }

    componentWillUnmount() {
        window.closeIframe = null;
    }

    getSetting = () => {
        Req(
            {
                url: '/v1/payment/profile',
                type: "GET",
                loadingTips: Tr("searching"),
            },
            res => {
                if (res && res.data) {
                    console.log('/v1/payment/profile')
                    console.log(res.data)
                    this.setState({
                        data: res.data
                    })
                }
            }
        )

        const storeData = JSON.parse(localStorage.getItem("storeData"));

        // 获取支付设置信息接口
        Req(
            {
                url: `/v1/store/getStoreFinancial?storeId=${storeData.currentStore.id}`,
                type: "GET",
                loadingTips: Tr("searching"),
            },
            res => {
                if (res && res.data) {
                    this.setState({
                        data: {
                            ...this.state.data,
                            ...res.data
                        }
                    })
                }
            }
        )

        // 获取持卡人名接口
        Req(
            {
                url: `/v1/payment/getCardholderName?storeId=${storeData.currentStore.id}`,
                type: "GET",
                loadingTips: Tr("searching"),
            },
            res => {
                if (res && res.data) {
                    this.setState({
                        cardholderName: res.data
                    })
                }
            }
        )
    }

    loadProtocol = () => {
        const storeData = JSON.parse(localStorage.getItem("storeData"));
        // 获取支付协议
        Req(
            {
                url: `/v1/protocol?storeId=${storeData.currentStore.id}`,
                type: "GET",
                loadingTips: Tr("searching"),
            },
            res => {
                if (res && res.data) {
                    this.setState({
                        protocolList: [res.data]
                    })
                }
            }
        )
    }

    loadPaymentHistory = () => {
        const storeData = JSON.parse(localStorage.getItem("storeData"));
        // 获取支付历史记录接口
        Req(
            {
                url: `/v1/payment/getPaymentHistory?storeId=${storeData.currentStore.id}`,
                type: "GET",
                loadingTips: Tr("searching"),
            },
            res => {
                if (res && res.data) {
                    this.setState({
                        recordList: res.data
                    })
                }
            }
        )
    }

    openModules = () => {
        message.warning(Tr("payCardSetting29"));
    }

    goToAddBank = ()=>{
        // 提示同意协议
        if(!this.state.isRead) {
            message.warning(Tr("payCardSetting39"));

            return
        }

        this.props.history.push("/admin/store/bank/0");
    }

    render() {
        const {data, activeKey, protocolList, recordList} = this.state;

        return (
            <div className="page-container">
                <h3>
                    {Tr("payCardSetting1")}
                </h3>

                <Tabs activeKey={activeKey} onChange={(val) => {
                    this.setState({
                        activeKey: val
                    })
                }}>
                    <TabPane tab={Tr("payCardSetting50")} key="1">
                        <div className="store_pay_setting keyContainer">
                            <Fragment>
                                <h2>{Tr("payCardSetting28")}</h2>
                                <div style={{width: 500, margin: "0 auto"}}>
                                    <ul className="module-list">
                                        {
                                            MODULESLIST.map(item => {
                                                return <li className="flex">
                                                    <div style={{width: 90}}>{item.name}</div>
                                                    <div className="flex-grow center">
                                                        {data && data.modules && data.modules.includes(item.value) ? Tr("payCardSetting25") :
                                                            <span style={{color: "rgba(227, 42, 42, 1)"}}>{Tr("payCardSetting26")}</span>}
                                                    </div>
                                                    {
                                                        data && data.modules && data.modules.includes(item.value) ?
                                                            <div className="center" style={{width: 88}}><Checkbox checked/>
                                                            </div> :
                                                            <Button type="default" onClick={this.openModules}>{Tr("payCardSetting27")}</Button>
                                                    }
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </Fragment>
                        </div>
                    </TabPane>
                    <TabPane tab={Tr("payCardSetting1")} key="2">
                        {
                            data &&
                            <div className="store_pay_setting keyContainer">
                                <Row gutter={[16, 16]}>
                                    <Col span={2}>
                                        <p className="label">{ Tr("payCardSetting40")}：</p>
                                    </Col>
                                    <Col span={8}><Input value={data.period ? data.period + Tr("payCardSetting41") : ''} disabled width={100} /></Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={2}>
                                        <p className="label">{ Tr("payCardSetting42")}：</p>
                                    </Col>
                                    <Col span={8}><Input value={data.start_at ? moment(data.start_at * 1000).format('YYYY年MM月DD日') : ''} disabled width={100} /></Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={2}>
                                        <p className="label">{ Tr("payCardSetting43")}：</p>
                                    </Col>
                                    <Col span={8}>
                                        <Input value={data.has_iva == 1 ? (data.amount * 1.21).toFixed(2) : data.amount} disabled width={100} />
                                    </Col>
                                    {
                                        data.has_iva &&
                                        <Col span={8}>
                                            Euro（{ data.has_iva == 1 ? Tr("payCardSetting44") : data.has_iva == 0 ? Tr("payCardSetting45") : ''}）
                                        </Col>
                                    }
                                </Row>
                            </div>
                        }

                        {/* begin 添加银行卡信息 */}
                        <div style={{padding: 20}}>
                            <h4 style={{marginBottom: 20}}>{Tr("payCardSetting47")}</h4>
                            <Card>
                                {
                                    !this.state.cardholderName ?
                                    <div>
                                        <Row>
                                            <Col span={18}><div>{Tr("payCardSetting48")}</div></Col>
                                            <Col span={6}>
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        this.goToAddBank()
                                                    }}
                                                    style={{marginBottom: 20}}
                                                >{Tr("payCardSetting49")}</Button>
                                                <div>
                                                    <Checkbox onChange={(val) => {
                                                        this.setState({
                                                            isRead: val.target.checked
                                                        })
                                                    }} checked={this.state.isRead}>{ Tr("payCardSetting38") }
                                                    </Checkbox>
                                                    <span style={{color: '#FFA600'}} onClick={(event) => {
                                                        event.stopPropagation()
                                                        this.setState({
                                                            showProtocol: true
                                                        })
                                                        this.loadProtocol()
                                                    }}>{ Tr("payCardSetting39") }</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div> :
                                    <div>
                                        <div>{ Tr("payCardSetting60") }</div>
                                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
                                            <div>{ Tr("payCardSetting61") }： {this.state.cardholderName}</div>
                                            <div style={{ color: '#FFA600'}}>{ Tr("payCardSetting62") }</div>
                                        </div>
                                    </div>
                                }
                            </Card>
                        </div>
                        {/* end 添加银行卡信息 */}
                    </TabPane>

                    {/* end 历史记录 */}
                    <TabPane tab={Tr("payCardSetting51")} key="3">
                        <div className="table-list">
                            <table style={{width: 1000}}>
                                <thead>
                                    <tr>
                                        <th>{ Tr("payCardSetting52") }</th>
                                        <th>{ Tr("payCardSetting53") }</th>
                                        <th>{ Tr("payCardSetting54") }</th>
                                        <th>{ Tr("payCardSetting55") }</th>
                                        <th>{ Tr("payCardSetting56") }€</th>
                                        <th>{ Tr("payCardSetting57") }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    recordList && !!(recordList.length != 0) && recordList.map((val, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{val.order_time}</td>
                                            <td>{val.order_number}</td>
                                            <td>{val.cardholderName}</td>
                                            <td>{val.payment_type}</td>
                                            <td>{val.amount}</td>
                                            <td>{val.created_at}</td>
                                        </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </TabPane>
                    {/* end 历史记录 */}

                </Tabs>

                <Modal visible={this.state.showProtocol} onCancel={() => {
                        this.setState({
                            showProtocol: false
                        })
                    }}
                    footer={false}
                    width={1000}
                >
                    <Tabs defaultActiveKey="1">
                        {
                            protocolList.map((item) => {
                                return(
                                    <TabPane tab={item.title} key={item.id}>
                                        <div dangerouslySetInnerHTML={{__html: item && item.content}}/>
                                    </TabPane>
                                )
                            })
                        }
                    </Tabs>

                    <Button type="primary" onClick={() => {
                        this.setState({
                            showProtocol: false,
                            isRead: true
                        })
                    }}>{ Tr("payCardSetting14") }</Button>
                </Modal>
            </div>
        )
    }
}
