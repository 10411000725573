import React, { Component } from "react";
import "./index.scss";
const QRCode = require('qrcode.react');

export default class AddDesk extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.print()
  }

  render() {
    let url = ''

    switch (process.env.NODE_ENV) {
      case 'DEV':
        url = window.protocolVar + '//qrcode-dev.g-eat.es'
        break
      case 'STAGING':
        url = window.protocolVar + '//qrcode-dev.g-eat.es'
        break;
      case 'PROD':
        url = window.protocolVar + '//qrcode.g-eat.es'
        break;
      default:
        window.serverUrl = window.protocolVar + "//api.g-eat.es/store";
    }

    const qrcode_src = `${url}/#/home?storeGuid=${this.props.match.params.guid}`

    return (
      <div className='qrcode-store'>
          <QRCode value={qrcode_src} size={800}/>
      </div>
    );
  }
}

