import zh from './ZH';
import es from './Spanish'; //西班牙

let Dics = {
    zh, es
}

export default function Tr(key){
    let language = window.selectedLanguage || 'zh';
    let dic = Dics[language];
    if(!dic[key]) {
        console.log(`${key}:未找到对应de翻译字段, 中文替换`);
        return Dics['zh'][key] || key;
    }
    return dic[key] || '';
}
