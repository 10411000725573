import React from 'react';
import InputNumber from "../InputNumber";
import Tr from "@util/translate";
import Req from "@util/fetch";
import { Modal } from "antd";
import './index.scss';

export default class ConfrimPassword extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        text: ''
    }
    textChange = (str) => {
        this.setState({
            text: str
        })
    }
    sureAction = () => {
        const { text } = this.state;
        const { callback } = this.props;
        let storeData = JSON.parse(localStorage.getItem('storeData'));
        Req(
            {
                url: "/v1/employee/pwdVerify",
                type: "POST",
                data: {
                    employeeGuid: storeData.guid,
                    password: text
                }
            },
            res => {
                if (res) {
                    callback && callback();
                    window.showConfrimPassword = false;
                    Modal.destroyAll();
                    this.setState({
                        list: res.data || []
                    })
                }
            }
        );
    }

    render() {
        const {text} = this.state;
        return (
            <div className="confirmUsersPassword">
                <div className="title">{Tr('add256')}</div>
                <div className="showArea">
                    { new Array(text.length).fill('*') }
                </div>
                <InputNumber text={text} textChange={this.textChange} sureAction={this.sureAction}/>
            </div>
        )
    }
}