import React from "react";
import {Spin} from "antd";
import "./index.scss";

export default class Loading extends React.Component {
    render() {
        const { style } = this.props;
        return (
            <div className="loading-container" style={{ ...(style || {}) }}>
                <Spin size="large" tip="Loading..."/>
            </div>
        );
    }
}
